export const LIST_LOADING = 'LIST_LOADTING';
export const LIST_LOADED = 'LIST_LOADED';
export const SIGNING_UP = 'SIGNING_UP';
export const SIGNED_UP = 'SIGNED_UP';
export const CONFIRMING = 'CONFIRMING';
export const CONFIRMED = 'CONFIRMED';
export const COGNITO_UPDATING = 'COGNITO_UPDATING';
export const COGNITO_UPDATED = 'COGNITO_UPDATED';
export const DYNAMODB_UPDATING = 'DYNAMODB_UPDATING';
export const DYNAMODB_UPDATED = 'DYNAMODB_UPDATED';
export const LOADING = 'LOADTING';
export const LOADED = 'LOADED';
export const ENABLING = 'ENABLING';
export const ENABLED = 'ENABLED';
export const DISABLING = 'DISABLING';
export const DISABLED = 'DISABLED';
export const PREVIEW_ON = 'PREVIEW_ON';
export const PREVIEW_OFF = 'PREVIEW_OFF';

// confirmの失敗時の時間(ミリ秒).
export const CONFIRM_WAIT_TIME = 2000;

// confirmの失敗時のリトライ数.
export const CONFIRM_RETRY_MAX_CNT = 5;

// 詳細用モデル.
export const Model: { [s: string]: string } = {
  institution_name: '介護事業所名',
  institution_name_kana: '介護事業所名(カナ)',
  company_name: '法人名',
  company_name_kana: '法人名(カナ)',
  institution_id: 'アカウントID',
  application_year_month: '入会年月(YYYYMM)',
  institution_phone: '電話番号',
  institution_email: 'メールアドレス',
  post_code: '郵便番号',
  address: '住所',
  fax: 'ファックス',
  Administrator: '管理者名',
  ContactPerson: '窓口担当者',
  iPadCode: 'iPadパスコード',
  institution_care_id: '介護保険事業所番号',
  enabled: 'ユーザーの有効・無効',
  plan_type: 'ユーザーの契約プラン',
};

export const email_param = {
  Destination: {
    ToAddresses: ['moff-training@moff.mobi'],
  },
  Message: {
    Body: {
      Text: {
        Data: '',
        Charset: 'utf-8',
      },
    },
    Subject: {
      Data: '',
      Charset: 'utf-8',
    },
  },
  ReplyToAddresses: ['moff-training@moff.mobi'],
  ReturnPath: 'moff-training@moff.mobi',
  Source: 'moff-training@moff.mobi',
};
