import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
// eslint-disable-next-line no-unused-vars
import { createMuiTheme, Theme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

const muiTheme: Theme = createMuiTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
      contrastText: '#fff',
    },
    secondary: {
      light: '#F9F9F9',
      main: '#fff',
      dark: '#ccc',
      contrastText: '#000',
    },
  },
});

interface LayoutProps extends React.Props<{}> {
  childern?: any;
}

export default class Layout extends Component<LayoutProps, {}> {
  render() {
    return <MuiThemeProvider theme={muiTheme}>{this.props.children}</MuiThemeProvider>;
  }
}
