import React, { FC } from 'react';
import {
  Button,
  Box,
  Container,
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core';
import { FetchUserDataType } from '../../constants/User';
import { CheckDataName, CheckNames } from '../../constants/Check';
import MoffCheckForm from '../uiParts/MoffCheckForm';

interface MoffCheckProps {
  institution: any;
  user: FetchUserDataType;
  users: FetchUserDataType[];
  selectedUniqueId: string;
  selectMonth: string;
  valueObj: { [key: string]: string };
  dateHandleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  valueHandleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  updateData: () => void;
  item: CheckDataName;
  date: string;
}

const MoffCheck: FC<MoffCheckProps> = ({
  institution,
  user,
  users,
  selectedUniqueId,
  selectMonth,
  valueObj,
  dateHandleChange,
  valueHandleChange,
  handleChange,
  updateData,
  item,
  date,
}: MoffCheckProps) => {
  return (
    <Container>
      <Box my={4} borderBottom={1} fontSize={35} fontWeight="fontWeightBold">
        <Grid container justify="space-between" spacing={2}>
          <Grid item>{institution.institution_name + '/' + user.user_name}</Grid>
          <Grid item>{selectMonth}</Grid>
        </Grid>
        <Box fontSize={25} justifyContent="center">
          <span>項目: {CheckNames[item]}</span>
        </Box>
      </Box>
      <MoffCheckForm
        selectItem={item}
        datetime={date}
        valueObj={valueObj}
        dateHandleChange={dateHandleChange}
        valueHandleChange={valueHandleChange}
      />
      <Box my={10} mx={20}>
        <Box my={3} fontSize={20} fontWeight="fontWeightBold">
          他の利用者として保存する場合は、利用者選択を行ってください。
        </Box>
        <Box textAlign="center">
          <FormControl style={{ minWidth: 400 }} data-testid="another-user-select">
            <InputLabel id="demo-simple-select-helper-label">利用者</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={selectedUniqueId}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>利用者を選択してください</em>
              </MenuItem>
              {users
                .filter(ele => ele.user_id !== user.user_id)
                .map((other, i) => {
                  return (
                    <MenuItem key={i} value={other.unique_id}>
                      {other.user_name}（{other.user_care_id}）
                    </MenuItem>
                  );
                })}
            </Select>
            <FormHelperText>データを保存したい利用者を選択</FormHelperText>
          </FormControl>
          <Box mt={10}>
            <Box>
              <Button color="primary" variant="contained" size="large" onClick={updateData}>
                更新
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default MoffCheck;
