import * as Auth from '../../constants/Auth';

export const initialState = {
  isAuthenticated: false,
  isSuperUser: false,
  err: null,
  lastSignedIn: null,
  Loading: false,
  Loaded: false,
  Result: false,
};

export default function auth(state = initialState, action: any) {
  switch (action.type) {
    case Auth.PROCESS_SIGN_IN:
      return Object.assign({}, state, {
        Loading: true,
        Loaded: false,
        Result: false,
      });
    case Auth.DONE_SIGN_IN:
      return Object.assign({}, state, {
        Loading: false,
        Loaded: true,
        isAuthenticated: action.isAuthenticated,
        isSuperUser: action.isSuperUser,
        err: action.err,
        Result: action.result,
      });
    case Auth.PROCESS_SIGN_OUT:
      return Object.assign({}, state, {
        Loading: true,
        Loaded: false,
        Result: false,
      });
    case Auth.DONE_SIGN_OUT:
      return Object.assign({}, state, {
        Loading: false,
        Loaded: true,
        isAuthenticated: false,
        isSuperUser: false,
        err: action.err,
        Result: action.result,
      });
    case Auth.PROCESS_GET_SESSION:
      return Object.assign({}, state, {
        Loading: true,
        Loaded: false,
        Result: false,
      });
    case Auth.DONE_GET_SESSION:
      return Object.assign({}, state, {
        Loading: false,
        Loaded: true,
        isAuthenticated: action.isAuthenticated,
        isSuperUser: action.isSuperUser,
        err: action.err,
        Result: action.result,
      });
    default:
      return state;
  }
}
