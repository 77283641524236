import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UsersNew from '../components/User/UsersNew';
import * as UserActions from '../actions/User/actions';
import * as institutionActions from '../actions/Institution';
import { RootState } from '../store/configureStore';
import { Dispatch } from 'redux';

function mapStateToProps(state: RootState) {
  return {
    institution: state.institution,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
    institutionActions: bindActionCreators(institutionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersNew);
