import * as Institution from '../constants/Institution';
import * as InstitutionAPI from '../api/Institution';
import * as InstitutionCognito from '../api/InstitutionCognito';

/**
 * 新規事業登録.
 * signUpしてconfirm
 *
 * @export
 * @param {*} institutionCareId アカウントID
 * @param {*} password パスワード.
 * @param {*} attributeList
 * @returns
 */
export function signUpAndConfirm(institutionCareId: string, password: string, attributeList: any) {
  return InstitutionCognito.signUp(
    institutionCareId,
    password,
    attributeList,
    () => {
      return {
        type: Institution.SIGNING_UP,
      };
    },
    (result: string, data: any) => {
      if (result) {
        return confirm(institutionCareId);
      } else {
        return {
          type: Institution.SIGNED_UP,
          result: result,
          err: data,
        };
      }
    },
  );
}

/**
 * 施設新規登録.
 *
 * @export
 * @param {*} institutionCareId
 * @param {*} password
 * @param {*} attributeList
 * @returns
 */
export function signUp(institutionCareId: string, password: string, attributeList: any) {
  return InstitutionCognito.signUp(
    institutionCareId,
    password,
    attributeList,
    () => {
      return {
        type: Institution.SIGNING_UP,
      };
    },
    (result: string, data: any) => {
      if (result) {
        return {
          type: Institution.SIGNED_UP,
          result: result,
          data: data,
        };
      } else {
        return {
          type: Institution.SIGNED_UP,
          result: result,
          err: data,
        };
      }
    },
  );
}

// 更新前の確認プレビュー
export function preview(on: any) {
  if (on) {
    return {
      type: Institution.PREVIEW_ON,
    };
  }

  return {
    type: Institution.PREVIEW_OFF,
  };
}

/**
 * 施設新規登録(confirm).
 *
 * @export
 * @param {*} institutionCareId
 * @returns
 */
export function confirm(institutionCareId: string) {
  return InstitutionCognito.confirm(
    institutionCareId,
    () => {
      return {
        type: Institution.CONFIRMING,
      };
    },
    (result: string, data: any) => {
      if (result) {
        return {
          type: Institution.CONFIRMED,
          result: result,
          data: data,
        };
      } else {
        return {
          type: Institution.CONFIRMED,
          result: result,
          err: data,
        };
      }
    },
  );
}

/**
 * 施設情報を更新.
 * ※Cognitoも更新される.
 * @export
 * @param {*} institutionSub
 * @param {*} body
 * @returns
 */
export function updateDynamoDB(institutionSub: any, body: any) {
  return InstitutionAPI.requestUpdateInstitution(
    institutionSub,
    body,
    () => {
      return {
        type: Institution.DYNAMODB_UPDATING,
      };
    },
    (result: any, data: any) => {
      // 正常.
      if (result) {
        return {
          type: Institution.DYNAMODB_UPDATED,
          result: result,
          data: data,
        };
      } else {
        // エラー.
        return {
          type: Institution.DYNAMODB_UPDATED,
          result: result,
          err: data,
        };
      }
    },
  );
}

/**
 * updateCognitoのAPIとENABLE/DISABLEのAPIの呼び出し.
 *
 * @export
 * @param {*} institutionSub
 * @param {*} attributes
 * @param {*} institutionId
 * @param {*} isEnable
 * @returns
 */
export function updateDynamoDBAndEnable(institutionSub: any, attributes: any, institutionId: any, isEnable: any) {
  return InstitutionAPI.requestUpdateInstitution(
    institutionSub,
    attributes,
    () => {
      return {
        type: Institution.DYNAMODB_UPDATING,
      };
    },
    (result: string, data: any) => {
      // ENABLED/DISABLEDは同じ引数なので関数オブジェクト切り替え.
      const enableDisableFunc = isEnable
        ? InstitutionAPI.requestEnableInstitutionById
        : InstitutionAPI.requestDisableInstitutionById;

      if (result) {
        return enableDisableFunc(
          institutionId,
          () => {
            return {
              type: isEnable ? Institution.ENABLING : Institution.DISABLING,
            };
          },
          (result: any, data: any) => {
            if (result) {
              return {
                type: Institution.DYNAMODB_UPDATED,
                result: result,
                data: data,
              };
            } else {
              return {
                type: Institution.DYNAMODB_UPDATED,
                result: result,
                err: data,
              };
            }
          },
        );
      } else {
        return {
          type: Institution.DYNAMODB_UPDATED,
          result: result,
          err: data,
        };
      }
    },
  );
}

export function loadList() {
  return InstitutionAPI.requestGetInstitutions(
    () => {
      return {
        type: Institution.LIST_LOADING,
      };
    },
    (result: any, data: any) => {
      if (result) {
        return {
          type: Institution.LIST_LOADED,
          result: result,
          data: data['data'].sort((a: any, b: any) => a.id > b.id),
        };
      } else {
        return {
          type: Institution.LIST_LOADED,
          result: result,
          err: data,
        };
      }
    },
  );
}

/**
 * ID(institution_sub)指定して取得.
 *
 * @export
 * @param {*} institutionSub
 * @returns
 */
export function get(institutionSub: any) {
  return InstitutionAPI.requestGetInstitutionById(
    institutionSub,
    () => {
      return {
        type: Institution.LOADING,
      };
    },
    (result: any, data: any) => {
      if (result) {
        return {
          type: Institution.LOADED,
          result: result,
          data: data,
        };
      } else {
        return {
          type: Institution.LOADED,
          result: result,
          err: data,
        };
      }
    },
  );
}

/**
 * ID(institution_id)指定してEnable
 *
 * @export
 * @param {*} institutionId
 * @returns
 */
export function enable(institutionId: any) {
  return InstitutionAPI.requestEnableInstitutionById(
    institutionId,
    () => {
      return {
        type: Institution.ENABLING,
      };
    },
    (result: any, data: any) => {
      if (result) {
        return {
          type: Institution.ENABLED,
          result: result,
          data: data,
        };
      } else {
        return {
          type: Institution.ENABLED,
          result: result,
          err: data,
        };
      }
    },
  );
}

/**
 * ID(institution_id)指定してDisable
 *
 * @export
 * @param {*} institutionId
 * @returns
 */
export function disable(institutionId: any) {
  return InstitutionAPI.requestDisableInstitutionById(
    institutionId,
    () => {
      return {
        type: Institution.DISABLING,
      };
    },
    (result: any, data: any) => {
      if (result) {
        return {
          type: Institution.DISABLED,
          result: result,
          data: data,
        };
      } else {
        return {
          type: Institution.DISABLED,
          result: result,
          err: data,
        };
      }
    },
  );
}
