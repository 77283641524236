import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import * as InstitutionActions from '../actions/Institution';
import InstitutionCreateForm from '../components/InstitutionCreateForm';
import { Model } from '../constants/Institution';
import { change } from 'redux-form';

const selector = formValueSelector('institutionCreateForm');

function mapStateToProps(state: any) {
  // アカウントID.
  const institution_id = selector(state, 'institution_id');
  // 介護保険事業所番号.
  const institution_care_id = selector(state, 'institution_care_id');
  const institution_name = selector(state, 'institution_name');
  const institution_name_kana = selector(state, 'institution_name_kana');
  const company_name = selector(state, 'company_name');
  const company_name_kana = selector(state, 'company_name_kana');
  const application_year_month = selector(state, 'application_year_month');
  const institution_phone = selector(state, 'institution_phone');
  const institution_email = selector(state, 'institution_email');
  const post_code = selector(state, 'post_code');
  const address = selector(state, 'address');
  const fax = selector(state, 'fax');
  const Administrator = selector(state, 'Administrator');
  const ContactPerson = selector(state, 'ContactPerson');
  const iPadCode = selector(state, 'iPadCode');
  const plan_type = selector(state, 'plan_type');
  // const enabled = selector(state, 'enabled');
  const password = selector(state, 'password');

  return {
    institution: state.institution,
    initialValues: { plan_type: 'Regular' },
    validate: validate,
    onChange: onChange,
    institutionCreateForm: {
      institution_id,
      institution_care_id,
      institution_name,
      institution_name_kana,
      company_name,
      company_name_kana,
      application_year_month,
      institution_phone,
      institution_email,
      post_code,
      address,
      fax,
      Administrator,
      ContactPerson,
      iPadCode,
      plan_type,
      // enabled,
      password,
    },
    auth: state.auth,
  };
}

// 介護保険事業所番号と入会年月を編集するとアカウントIDとパスワードをセット.
const onChange = (values: any, dispatch: any, _: any, previousValues: any) => {
  // アカウントID.
  const institution_id = values['institution_id'] || '';

  // 入会年月.
  const yearMonth = values['application_year_month'] || '';

  // 入会年月は半角に自動変換.
  if (yearMonth !== previousValues['application_year_month']) {
    const halfWidth = yearMonth.replace(/[０-９]/g, (s: string) => {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    });
    dispatch(change('institutionCreateForm', 'application_year_month', halfWidth));
  }

  if (institution_id !== previousValues['institution_id']) {
    // アカウントID.
    dispatch(change('institutionCreateForm', 'institution_id', institution_id.trim()));
  }

  if (institution_id !== previousValues['institution_id']) {
    // 半角に自動変換.
    const halfWidth = institution_id.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s: string) => {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    });
    dispatch(change('institutionCreateForm', 'institution_id', halfWidth));
  }

  if (institution_id !== previousValues['institution_id'] || yearMonth !== previousValues['application_year_month']) {
    // 初期パスワードはアカウントIDの逆順＋入会の西暦年月(6桁[0-9])
    const password =
      institution_id
        .trim()
        .split('')
        .reverse()
        .join('') + yearMonth.trim();
    dispatch(change('institutionCreateForm', 'password', password));
  }
};

// バリデーションチェック.
const validate = (values: any) => {
  const errors: { [s: string]: string } = {};

  // 必須項目.
  const requiredFields = [
    'institution_name',
    'institution_name_kana',
    'company_name',
    'company_name_kana',
    // アカウントIDも必須.
    'institution_id',
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = `${Model[field]}が未入力です。`;
    }
  });

  // アカウントID(半角英数字).
  if (!/^[A-Z0-9]*$/i.test(values.institution_id)) {
    errors.institution_id = `${Model['institution_id']}を正しく入力してください。(半角数字)`;
  }

  // メールアドレス.
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.institution_email)) {
    errors.institution_email = `${Model['institution_email']}を正しく入力してください。`;
  }

  // YYYYMM確認.
  const isMonthDate = (str: string) => {
    str = str == null ? '' : str;
    if (str.match(/^\d{4}\d{2}$/)) {
      return true;
    } else {
      return false;
    }
  };

  // 入会年月項目.
  const monthDateFields = ['application_year_month'];

  // 入会年月項目.
  monthDateFields.forEach(field => {
    if (!isMonthDate(values[field])) {
      errors[field] = `${Model[field]}を正しく入力してください。`;
    }
  });

  const isKatakana = (str: string) => {
    str = str == null ? '' : str;
    // eslint-disable-next-line no-irregular-whitespace
    if (str.match(/^[ァ-ヶー　]*$/)) {
      return true;
    } else {
      return false;
    }
  };

  // カタカナ項目.
  const katakanaFields = ['institution_name_kana', 'company_name_kana'];

  // カタカナ.
  katakanaFields.forEach(field => {
    if (!isKatakana(values[field])) {
      errors[field] = `${Model[field]}を正しく入力してください。`;
    }
  });

  return errors;
};

function mapDispatchToProps(dispatch: any) {
  return {
    institutionActions: bindActionCreators(InstitutionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionCreateForm);
