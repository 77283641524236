import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import InstitutionsList from '../containers/InstitutionsList';
import InstitutionDetail from '../containers/InstitutionDetail';
import InstitutionCreateForm from '../containers/InstitutionCreateForm';
import InstitutionUpdateForm from '../containers/InstitutionUpdateForm';
import NotFound from './common/NotFound';
import Users from '../containers/Users';
import UsersCreated from '../containers/UsersCreated';
import UsersNew from '../containers/UsersNew';
import SelectMonth from '../containers/Check/SelectMonth';
import MoffCheckCreate from '../containers/Check/MoffCheckCreate';
import MoffCheckList from '../containers/Check/MoffCheckList';
import MoffCheck from '../containers/Check/MoffCheckEdit';

export default class Routes extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/institutions/edit" component={NotFound} />
          <Route path="/institutions/list" component={InstitutionsList} />
          <Route path="/institutions/detail" component={InstitutionDetail} />
          <Route path="/institutions/password" component={NotFound} />
          <Route path="/institutions/create" component={InstitutionCreateForm} />
          <Route path="/institutions/update" component={InstitutionUpdateForm} />
          <Route path="/institutions/users/:institution_sub/new" component={UsersNew} />
          <Route path="/institutions/users/:institution_sub/created" component={UsersCreated} />
          <Route path="/institutions/users/:institution_sub" component={Users} />
          <Route path="/institutions/:institutionId/select_month/:userId" component={SelectMonth} />
          <Route path="/institutions/:institutionId/check/:userId/create" component={MoffCheckCreate} />
          <Route path="/institutions/:institutionId/check/:userId/:selectMonth/:item/:date" component={MoffCheck} />
          <Route path="/institutions/:institutionId/check/:userId/:selectMonth" component={MoffCheckList} />
        </Switch>
      </div>
    );
  }
}
