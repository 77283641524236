import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as InstitutionActions from '../actions/Institution';
import InstitutionDetail from '../components/InstitutionDetail';
import { RootState } from '../store/configureStore';
import { Dispatch } from 'redux';

function mapStateToProps(state: RootState) {
  return {
    institution: state.institution,
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    institutionActions: bindActionCreators(InstitutionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionDetail);
