import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';

import { RootState } from '../../store/configureStore';
import * as UserActions from '../../actions/User/actions';
import * as CheckActions from '../../actions/Check/actions';
import { validateAndConvertNumber, getApiName, getRequestBody } from '../../utils/MoffCheck';
import Loading from '../../components/common/Loading';
import BackButton from '../../components/common/BackButton';
import MoffCheckCreate from '../../components/Check/MoffCheckCreate';
import { CheckDataName, CheckItemDisplay } from '../../constants/Check';
import { FetchUserDataType } from '../../constants/User';

const MoffCheckCreateContainer: FC = () => {
  const { institutionId, userId } = useParams<{ institutionId: string; userId: string }>();
  const dispatch = useDispatch();
  const users = useSelector((state: RootState) => state.user.listData);
  const user: FetchUserDataType = users.find((user: FetchUserDataType) => user.user_id === userId);
  const executing = useSelector((state: RootState) => state.check.executing);
  const created = useSelector((state: RootState) => state.check.created);

  const prepareInitialValues = (item: CheckDataName) => {
    const keys = Object.keys(CheckItemDisplay[item]);
    const initialValue: { [key: string]: string } = {};
    keys.forEach(key => {
      initialValue[key] = '';
    });
    return initialValue;
  };
  const [item, setItem] = useState<CheckDataName>('tug');
  const [datetime, setDatetime] = useState('');
  const [values, setValues] = useState<{ [key: string]: string }>(prepareInitialValues(item));

  useEffect(() => {
    if (users.length === 0) {
      dispatch(UserActions.getUsersWithUniqueId(institutionId));
    }
  }, [users, institutionId, dispatch]);

  const itemHandleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectItem = event.target.value as CheckDataName;
    setItem(selectItem);
    setDatetime('');

    const initialValue = prepareInitialValues(selectItem);
    setValues(initialValue);
  };

  const dateHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDatetime(event.target.value);
  };

  const valueHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const saveData = () => {
    const apiName = getApiName(item);
    const unique_id = user.unique_id;

    if (datetime !== '') {
      const convertValue = validateAndConvertNumber(values);
      if (convertValue) {
        const body = getRequestBody(item, convertValue, unique_id, datetime);
        dispatch(CheckActions.postCheckData(apiName, unique_id, body));
      }
    } else {
      alert('実施日時を入力してください');
    }
  };

  if (created) {
    alert('作成しました。');
    return <Redirect to={`/institutions/${institutionId}/select_month/${userId}`} />;
  }

  return executing ? (
    <Loading />
  ) : (
    <>
      <BackButton />
      <MoffCheckCreate
        selectItem={item}
        datetime={datetime}
        valueObj={values}
        itemHandleChange={itemHandleChange}
        dateHandleChange={dateHandleChange}
        valueHandleChange={valueHandleChange}
        saveData={saveData}
      />
    </>
  );
};

export default MoffCheckCreateContainer;
