import React, { Component } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  withStyles,
} from '@material-ui/core';
import UserTableRow from './UserTableRow';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

interface UsersListProps extends React.Props<{}> {
  users: any;
  invalidUsers: any;
  withLink: boolean;
  institutionSub?: string;
}

export default class UsersList extends Component<UsersListProps, {}> {
  render() {
    return (
      <Box mt={3} mb={3}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">氏名</StyledTableCell>
                <StyledTableCell align="left">ふりがな</StyledTableCell>
                <StyledTableCell align="left">性別</StyledTableCell>
                <StyledTableCell align="left">生年月日</StyledTableCell>
                <StyledTableCell align="left">介護保険番号</StyledTableCell>
                <StyledTableCell align="left">要介護度</StyledTableCell>
                <StyledTableCell align="left">訪問日</StyledTableCell>
                <StyledTableCell align="left">保険者番号</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.users.map((user: any, i: number) => {
                return (
                  <UserTableRow
                    user={user}
                    isValid={true}
                    key={i}
                    institutionSub={this.props.institutionSub}
                    withLink={this.props.withLink}
                  />
                );
              })}
              {this.props.invalidUsers.map((invalidUser: any, i: number) => {
                return <UserTableRow user={invalidUser} isValid={false} key={i} withLink={this.props.withLink} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
}
