export enum UserActionTypes {
  START_FETCH = 'USER_START_FETCH',
  END_FETCH = 'USER_END_FETCH',
  START_CSV_LOAD = 'USER_START_CSV_LOAD',
  END_CSV_LOAD = 'USER_END_CSV_LOAD',
  START_CREATE = 'USER_START_CREATE',
  END_CREATE = 'USER_END_CREATE',
  API_ERROR = 'USER_API_ERROR',
  CSV_LOAD_ERROR = 'USER_CSV_LOAD_ERROR',
}

export type UserDataType = {
  user_name: string;
  user_name_kana: string;
  user_care_id: string;
  user_birth_year: number;
  user_birth_month: number;
  user_birth_day: number;
  user_care_level: number;
  user_gender: number;
  user_visit_day: number[];
  user_insurer_no: string;
};

export type FetchUserDataType = UserDataType & {
  created_at: string;
  functional_latest_date: string;
  institution_sub: string;
  unique_id: string;
  updated_at: string;
  user_id: string;
};

export enum UserCareLevelMap {
  '要支援1',
  '要支援2',
  '要介護1',
  '要介護2',
  '要介護3',
  '要介護4',
  '要介護5',
  '要介護なし' = 999,
}

export enum UserGenderMap {
  '男性',
  '女性',
}

export enum UserVisitMap {
  '〇' = 1,
}

export enum UserVisitDayOrderMap {
  '月',
  '火',
  '水',
  '木',
  '金',
  '土',
  '日',
}
