export const PROCESS_SIGN_IN = 'PROCESS_SIGN_IN';
export const DONE_SIGN_IN = 'DONE_SIGN_IN';
export const PROCESS_SIGN_OUT = 'PROCESS_SIGN_OUT';
export const DONE_SIGN_OUT = 'DONE_SIGN_OUT';
export const PROCESS_GET_SESSION = 'PROCESS_GET_SESSION';
export const DONE_GET_SESSION = 'DONE_GET_SESSION';
export const SIGN_UP = 'SIGN_UP';
export const SUCCESS_SIGN_UP = 'SUCCESS_SIGN_UP';
export const FAILURE_SIGN_UP = 'FAILURE_SIGN_UP';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const CONFIRM = 'CONFIRM';

// sign-in時のリダイレクト制御
export const SIGN_IN_STATUS_REDIRECTING = 'SIGN_IN_STATUS_REDIRECTING';
export const SIGN_IN_STATUS_SUCCESS = 'SIGN_IN_STATUS_SUCCESS';
