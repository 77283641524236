import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as InstitutionActions from '../../actions/Institution';
import * as UserActions from '../../actions/User/actions';
import * as CheckActions from '../../actions/Check/actions';
import { RootState } from '../../store/configureStore';
import MoffCheckList from '../../components/Check/MoffCheckList';
import Loading from '../../components/common/Loading';
import BackButton from '../../components/common/BackButton';

import { FetchUserDataType } from '../../constants/User';
import { CheckAPIName, CheckAPINamesList, CheckDataNamesList } from '../../constants/Check';

const MoffCheckContainer: FC = () => {
  const dispatch = useDispatch();
  const institution = useSelector((state: RootState) => state.institution.institution);
  const users = useSelector((state: RootState) => state.user.listData);
  const check = useSelector((state: RootState) => state.check.data);
  const checkIsLoaded = useSelector((state: RootState) => state.check.loaded);
  const isSuper = useSelector((state: RootState) => state.auth.isSuperUser);
  const deleted = useSelector((state: RootState) => state.check.deleted);
  const { institutionId, userId, selectMonth } = useParams<{
    institutionId: string;
    userId: string;
    selectMonth: string;
  }>();
  const [user, setUser] = useState(users.find((user: FetchUserDataType) => user.user_id === userId));

  useEffect(() => {
    if (!institution) {
      dispatch(InstitutionActions.get(institutionId));
    }
  }, [institution, institutionId, dispatch]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(UserActions.getUsersWithUniqueId(institutionId));
    }
  }, [users, institutionId, dispatch]);

  useEffect(() => {
    setUser(users.find((user: FetchUserDataType) => user.user_id === userId));
  }, [users, userId]);

  useEffect(() => {
    if (!checkIsLoaded && user) {
      dispatch(CheckActions.resetCheckData());
      dispatch(CheckActions.setUniqueId(user.unique_id));
      dispatch(
        CheckActions.getCheckDataByYear(
          CheckAPINamesList,
          user.unique_id,
          Number(selectMonth.split('-')[0]),
          Number(selectMonth.split('-')[1]),
        ),
      );
    }
  }, [user, selectMonth, checkIsLoaded, dispatch]);

  useEffect(() => {
    if (deleted) {
      alert('削除しました。');
      dispatch(CheckActions.resetCheckData());
      dispatch(CheckActions.setUniqueId(user.unique_id));
      dispatch(
        CheckActions.getCheckDataByYear(
          CheckAPINamesList,
          user.unique_id,
          Number(selectMonth.split('-')[0]),
          Number(selectMonth.split('-')[1]),
        ),
      );
    }
  }, [deleted, dispatch, selectMonth, user]);

  const records = Object.fromEntries(
    CheckDataNamesList.map(name => {
      if (check[name][selectMonth]) {
        return [name, check[name][selectMonth]];
      } else {
        return [name, []];
      }
    }),
  );

  const deleteMoffCheck = (name: CheckAPIName, date: string) => {
    const msg = '本当に削除してもよろしいですか？';
    if (window.confirm(msg)) {
      // 削除機能
      dispatch(CheckActions.deleteCheckData(name, user.unique_id, date));
    }
  };

  return user && institution && checkIsLoaded ? (
    <>
      <BackButton />
      <MoffCheckList
        institution={institution}
        user={user}
        selectMonth={selectMonth}
        checkData={records}
        deleteMoffCheck={deleteMoffCheck}
        isSuper={isSuper}
      />
    </>
  ) : (
    <Loading />
  );
};

export default MoffCheckContainer;
