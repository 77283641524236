import React from 'react';
import { Box, TextField, Switch, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import * as Institution from '../constants/Institution';
import { Field } from 'redux-form';

const styles = {
  formTextStyle: {
    width: '800px',
  },
  // フォーム内の薄い文字.
  floatingLabelStyle: {
    color: '#999',
  },
  // デフォルトのトグルの横幅が広いため調整.
  toggleStyle: {
    width: '220px',
  },
};

// 一旦クローン.
const copiedModel = Object.assign({}, Institution.Model);
const newModel = Object.assign(copiedModel, {
  // 微妙に違うため.
  iPadCode: 'iPad初期パスコード',
  password: 'パスワード',
});
// 利用者on/off消す.
delete newModel['enabled'];

const renderSelectField = ({ key, input, label, name, meta: { touched, error }, children, ...custom }: any) => (
  <FormControl error={touched && error}>
    <InputLabel>{label}</InputLabel>
    <Select key={key} name={name} {...input} {...custom}>
      {children}
    </Select>
  </FormControl>
);

const renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }: any) => {
  return (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      fullWidth
      InputProps={{
        readOnly: custom.readOnly,
        disableUnderline: custom.underlineStyle,
      }}
      {...input}
      {...custom}
    />
  );
};

const renderToggle = ({ input, ...custom }: any) => (
  <div>
    <Switch checked={input.value ? 1 : 0} onChange={input.onChange} color="primary" {...input} {...custom} />
  </div>
);

// 新規時の各フィールド.
// バリデーションや初期値はcontainers/InstitutionCreateForm.jsのコンテナで行う.
export const institutionNewField = Object.keys(newModel).map(key => {
  // 編集不可.
  let readOnly = false;
  // if (['institution_id', 'password'].includes(key)) {
  if (['password'].includes(key)) {
    readOnly = true;
  }

  if (key !== 'id') {
    if (key === 'plan_type') {
      return (
        <Box key={key} mt={3}>
          <Field
            key={key}
            name={key}
            label={Institution.Model[key]}
            component={renderSelectField}
            style={styles.formTextStyle}
            readOnly={readOnly}
          >
            <MenuItem value="Regular">通常</MenuItem>
            <MenuItem value="Light">ライトプラン</MenuItem>
            <MenuItem value="MoffCheck">モフトレチェックプラン</MenuItem>
          </Field>
        </Box>
      );
    } else {
      return (
        <Box key={key} mt={3}>
          <Field key={key} name={key} label={newModel[key]} component={renderTextField} readOnly={readOnly} />
        </Box>
      );
    }
  } else {
    return <div></div>;
  }
});

// 新規登録時のプレビュー画面.
export const institutionNewPreviewField = Object.keys(newModel).map(key => {
  if (key !== 'id' && key !== 'plan_type') {
    return (
      <Box key={key} mt={3}>
        <Field
          key={key}
          name={key}
          label={newModel[key]}
          component={renderTextField}
          // アンダーラインを消す
          underlineStyle={true}
          readOnly={true}
        />
      </Box>
    );
  } else if (key === 'plan_type') {
    return (
      <Box key={key} mt={3}>
        <Field
          key={key}
          name={key}
          label={Institution.Model[key]}
          component={renderSelectField}
          style={styles.formTextStyle}
          readOnly={true}
        >
          <MenuItem value="Regular">通常</MenuItem>
          <MenuItem value="Light">ライトプラン</MenuItem>
          <MenuItem value="MoffCheck">モフトレチェックプラン</MenuItem>
        </Field>
      </Box>
    );
  } else {
    return <div></div>;
  }
});

/**
 * バリデーションや初期値はcontainers/InstitutionUpdateForm.jsのコンテナで行う.
 * 編集時の各フィールド.
 *
 * @param {*} enabled ユーザーの有効・無効の切り替え.
 * @returns
 */
export const institutionField = (enabled: boolean) => {
  return Object.keys(Institution.Model).map(key => {
    // 編集不可.
    let disabled = false;
    if (['institution_id', 'application_year_month'].includes(key)) {
      disabled = true;
    }

    if (key !== 'id') {
      // 「ユーザーの有効・無効」はトグル.
      if (key === 'enabled') {
        return (
          <Box key={key} mt={3}>
            <table>
              <tr>
                <td>
                  <div>{Institution.Model[key]}</div>
                </td>
                <td>
                  <Field
                    key={key}
                    name={key}
                    label={Institution.Model[key]}
                    component={renderToggle}
                    disabled={disabled}
                  />
                </td>
                <td>
                  <div>{enabled ? '有効' : '無効'}</div>
                </td>
              </tr>
            </table>
          </Box>
        );
      } else if (key === 'plan_type') {
        return (
          <Box key={key} mt={3}>
            <Field
              key={key}
              name={key}
              label={Institution.Model[key]}
              component={renderSelectField}
              style={styles.formTextStyle}
              disabled={disabled}
            >
              <MenuItem value="Regular">通常</MenuItem>
              <MenuItem value="Light">ライトプラン</MenuItem>
              <MenuItem value="MoffCheck">モフトレチェックプラン</MenuItem>
            </Field>
          </Box>
        );
      } else {
        return (
          <Box key={key} mt={3}>
            <Field
              key={key}
              name={key}
              label={Institution.Model[key]}
              component={renderTextField}
              disabled={disabled}
            />
          </Box>
        );
      }
    } else {
      return <div></div>;
    }
  });
};

/**
 * 編集時のプレビュー画面.
 *
 * @param {*} enabled ユーザーの有効・無効の切り替え.
 * @param {*} disabled 確認面は「ユーザーの有効・無効」を変更不可能(true)、更新後は変更可能(false).
 * @returns
 */
export const institutionPreviewField = (enabled: boolean, disabled: boolean) => {
  return Object.keys(Institution.Model).map(key => {
    if (key !== 'id') {
      // 「ユーザーの有効・無効」はトグル.
      if (key === 'enabled') {
        return (
          <Box key={key} mt={3}>
            <table>
              <tr>
                <td>
                  <div>{Institution.Model[key]}</div>
                </td>
                <td>
                  <Field
                    key={key}
                    name={key}
                    component={renderToggle}
                    label={Institution.Model[key]}
                    // 有効・無効.
                    disabled={disabled}
                  />
                </td>
                <td>
                  <div>{enabled ? '有効' : '無効'}</div>
                </td>
              </tr>
            </table>
          </Box>
        );
      } else if (key === 'PlanType' || key === 'plan_type') {
        return (
          <Box key={key} mt={3}>
            <Field
              key={key}
              name={key}
              label={Institution.Model[key]}
              component={renderSelectField}
              style={styles.formTextStyle}
              readOnly={true}
            >
              <MenuItem value="Regular">通常</MenuItem>
              <MenuItem value="Light">ライトプラン</MenuItem>
              <MenuItem value="MoffCheck">モフトレチェックプラン</MenuItem>
            </Field>
          </Box>
        );
      } else {
        return (
          <Box key={key} mt={3}>
            <Field
              key={key}
              name={key}
              label={Institution.Model[key]}
              component={renderTextField}
              underlineStyle={true}
              readOnly={true}
            />
          </Box>
        );
      }
    } else {
      return <div></div>;
    }
  });
};
