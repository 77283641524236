import React, { FC } from 'react';
import { Button, Box, Container } from '@material-ui/core';
import { CheckDataName } from '../../constants/Check';
import MoffCheckForm from '../uiParts/MoffCheckForm';

interface MoffCheckCreateProps {
  selectItem: CheckDataName;
  datetime: string;
  valueObj: { [key: string]: string };
  itemHandleChange: (event: React.ChangeEvent<{ value: CheckDataName | unknown }>) => void;
  dateHandleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  valueHandleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  saveData: () => void;
}

const MoffCheckCreate: FC<MoffCheckCreateProps> = ({
  selectItem,
  datetime,
  valueObj,
  itemHandleChange,
  dateHandleChange,
  valueHandleChange,
  saveData,
}) => {
  return (
    <Container>
      <Box my={10}>
        <MoffCheckForm
          selectItem={selectItem}
          datetime={datetime}
          valueObj={valueObj}
          itemHandleChange={itemHandleChange}
          dateHandleChange={dateHandleChange}
          valueHandleChange={valueHandleChange}
        />
      </Box>
      <Box mt={10} textAlign="center">
        <Button color="primary" variant="contained" size="large" onClick={saveData}>
          登録
        </Button>
      </Box>
    </Container>
  );
};

export default MoffCheckCreate;
