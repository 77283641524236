import * as API from '../constants/API';
import * as AWSconst from '../constants/AWS';
import * as AWS from 'aws-sdk';
import aws4Interceptor from 'aws4-axios';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { asyncGetSession } from '../actions/Auth/api';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export class DayCareApi {
  api: AxiosInstance;

  static async ClientFactory() {
    const cognitoUser = AWSconst.userPool().getCurrentUser();
    if (cognitoUser === null) {
      throw new Error('the user is not logged in.');
    }
    const session = await asyncGetSession(cognitoUser);
    return new DayCareApi(session);
  }

  constructor(session: CognitoUserSession) {
    this.api = axios.create({
      baseURL: API.daycareApiRoot,
      headers: {
        Authorization: session.getIdToken().getJwtToken(),
      },
    });
  }
}

export class DayCareApiIamAuth {
  api: AxiosInstance;

  static async ClientFactory() {
    const cognitoUser = AWSconst.userPool().getCurrentUser();
    if (cognitoUser === null) {
      throw new Error('the user is not logged in.');
    }
    const session = await asyncGetSession(cognitoUser);
    AWS.config.region = process.env.REACT_APP_AWS_REGION;
    const credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID || '',
      Logins: {
        [AWSconst.cognitoIdpURL]: session.getIdToken().getJwtToken(),
      },
    });
    await credentials.getPromise();
    const interceptor = aws4Interceptor(
      {
        region: AWS.config.region,
        service: 'execute-api',
      },
      {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
    );
    return new DayCareApiIamAuth(interceptor);
  }

  constructor(intercepter: (config: AxiosRequestConfig) => AxiosRequestConfig) {
    this.api = axios.create({
      baseURL: API.daycareApiRoot,
    });
    this.api.interceptors.request.use(intercepter);
  }
}

export class EasyIdApi {
  private api: AxiosInstance;
  constructor() {
    this.api = getEasyIdApi();
  }

  set Api(api) {
    this.api = api;
  }

  get Api() {
    return this.api;
  }
}

const getEasyIdApi = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_APP_USER_API_URL,
    headers: {
      'x-api-key': process.env.REACT_APP_APP_USER_API_KEY,
    },
  });
};

const easyIdInstance = new EasyIdApi();

Object.freeze(easyIdInstance);

export { easyIdInstance };
