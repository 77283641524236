import { DayCareApi, easyIdInstance } from '../../api/BaseAPI';

export const getUsersByInstitutionSub = async (institutionSub: string) => {
  const client = await DayCareApi.ClientFactory();
  const res = await client.api.get(`/institutions/${institutionSub}/users`);
  return res.data.data;
};

export const getAppUsers = async (institutionSub: string) => {
  const url = `/v2/app/institution/${institutionSub}/app_id/1`;
  const appUser = await easyIdInstance.Api.get(url)
    .then(responce => {
      return responce.data;
    })
    .catch(error => {
      // ユニークIDが存在しないユーザーはnullを返す
      if (error.response.status === 404) {
        return null;
      }
      // それ以外は普通のエラーを返す
      throw error;
    });
  return appUser;
};

export const createUsersByInstitutionSub = async (institutionSub: string, userList: { [s: string]: any }[]) => {
  const date = new Date();
  const today = [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-');
  const batch_create_id = [institutionSub, today].join('_');
  const client = await DayCareApi.ClientFactory();

  const Tasks = userList.map(async user => {
    try {
      const res = await client.api.post(`/institutions/${institutionSub}/users`, user, { params: { batch_create_id } });
      const { status, data } = res;
      return { status, data, user };
    } catch (error) {
      const { status, data } = error.response;
      return { status, data, user };
    }
  });

  const res = await Promise.all(Tasks);
  const postSucceededData = res.filter(ele => ele.status === 201).map(ele => ele.data);
  const postFailedData = res.filter(ele => ele.status !== 201).map(ele => ele.user);

  return { postSucceededData, postFailedData };
};
