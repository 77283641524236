import { DayCareApiIamAuth } from './BaseAPI';
import * as AWS from '../constants/AWS';
import { CONFIRM_WAIT_TIME, CONFIRM_RETRY_MAX_CNT } from '../constants/Institution';

/**
 * 施設新規登録.
 *
 * @export
 * @param {*} institutionCareId アカウントID
 * @param {*} password パスワード.
 * @param {*} attributeList
 * @param {*} requestFunc
 * @param {*} fetchedFunc
 * @returns
 */
export function signUp(institutionCareId: any, password: any, attributeList: any, requestFunc: any, fetchedFunc: any) {
  return (dispatch: any) => {
    dispatch(requestFunc());

    AWS.institutionPool.signUp(institutionCareId, password, attributeList, [], (err: any, result: any) => {
      if (err) {
        dispatch(fetchedFunc(false, err));
      } else {
        dispatch(fetchedFunc(true, result));
      }
    });
  };
}

/**
 * リトライの仕組み.
 *
 * @param {*} dispatch
 * @param {*} fetchedFunc
 * @param {*} func
 * @param {*} retryCount 試行回数.
 * @param {*} waitTime 待ち時間.
 */
function retryable(dispatch: any, fetchedFunc: any, func: any, retryCount: any, waitTime: any) {
  func()
    .then(
      // いきなり成功.
      (response: any) => dispatch(fetchedFunc(true, response.data)),
    )
    .catch((err: any) => {
      let count = retryCount;
      count--;
      if (retryCount === 1) {
        // 失敗数が限界.
        dispatch(fetchedFunc(false, err));
      } else {
        // まだ実行可能(再帰的).
        setTimeout(() => {
          retryable(dispatch, fetchedFunc, func, count, waitTime);
        }, waitTime);
      }
    });
}

/**
 * 施設新規登録(confirm).
 *
 * @export
 * @param {*} institutionSub
 * @param {*} requestFunc
 * @param {*} fetchedFunc
 * @returns
 */
export function confirm(institutionSub: any, requestFunc: any, fetchedFunc: any) {
  return async (dispatch: any) => {
    dispatch(requestFunc());
    const client = await DayCareApiIamAuth.ClientFactory();

    retryable(
      dispatch,
      fetchedFunc,
      // AxiousInstanceを返す.
      () => {
        return client.api.post(
          '/institutions/' + institutionSub + '/confirm',
          {},
          { headers: { 'content-type': 'application/json;charset=UTF-8' } },
        );
      },
      CONFIRM_RETRY_MAX_CNT,
      CONFIRM_WAIT_TIME,
    );
  };
}
