import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { cognitoUser, authenticationDetail, userPool } from '../../constants/AWS';

const asyncAuthenticateUser = async (
  user: CognitoUser,
  authenticationDetails: AuthenticationDetails,
): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    user.authenticateUser(authenticationDetails, {
      onSuccess: session => {
        resolve(session);
      },
      onFailure: error => {
        reject(error);
      },
    });
  });
};

export const checkSuperUser = (session: CognitoUserSession): boolean => {
  const groups = session.getIdToken().payload['cognito:roles'];
  let ans = false;
  if (groups !== undefined) {
    groups.forEach((role: string) => {
      if (role.indexOf('Super_User_Role') !== -1) {
        ans = true;
      }
    });
  }
  return ans;
};

export const asyncGetSession = async (user: CognitoUser): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    user.getSession((err: any, session: CognitoUserSession) => {
      if (err) {
        reject(err);
      }
      resolve(session);
    });
  });
};

export const requestSignIn = async (username: string, password: string) => {
  const user = cognitoUser(username);
  return await asyncAuthenticateUser(user, authenticationDetail(username, password));
};

export const requestGetSession = async () => {
  const user = userPool().getCurrentUser();
  if (user != null) {
    const session = await asyncGetSession(user);
    return session;
  } else {
    throw new Error('User is not Signed In');
  }
};

export const requestSignOut = () => {
  const user = userPool().getCurrentUser();
  if (user != null) {
    user.signOut();
  }
};
