const daycareApiHost = process.env.REACT_APP_DAYCARE_API_HOST;
const daycareApiVersion = process.env.REACT_APP_DAYCARE_API_VERSION;

const daycareApiRoot = 'https://' + daycareApiHost + '/v' + daycareApiVersion;

export { daycareApiRoot };

export class APIBaseError extends Error {
  status: number;

  constructor(status: number, e?: string) {
    super(e);
    this.status = status;
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class BadRequestError extends APIBaseError {
  constructor(message: string) {
    super(400, message);
  }
}

export class UnauthorizedError extends APIBaseError {
  constructor(message: string) {
    super(403, message);
  }
}

export class NotFoundError extends APIBaseError {
  constructor(message: string) {
    super(404, message);
  }
}

export class ConflictError extends APIBaseError {
  constructor(message: string) {
    super(409, message);
  }
}

export class InternalServerError extends APIBaseError {}
