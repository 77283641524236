import React, { FC } from 'react';
import {
  Button,
  Box,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
} from '@material-ui/core';
import { FetchUserDataType } from '../../constants/User';
import { CheckAPIName, CheckData, CheckItemProperties, CheckNames } from '../../constants/Check';
import { Link } from 'react-router-dom';

interface MoffCheckListProps {
  institution: any;
  user: FetchUserDataType;
  selectMonth: string;
  checkData: { [s: string]: any[] };
  deleteMoffCheck: (name: CheckAPIName, date: string) => void;
  isSuper: boolean;
}

const MoffCheckList: FC<MoffCheckListProps> = ({
  institution,
  user,
  selectMonth,
  checkData,
  deleteMoffCheck,
  isSuper,
}: MoffCheckListProps) => {
  const count = Object.entries(checkData).reduce((sum, ele) => ele[1].length + sum, 0);
  return (
    <Container>
      <Box my={4} borderBottom={1} fontSize={35} fontWeight="fontWeightBold">
        <Grid container justify="space-between" spacing={2}>
          <Grid item>{institution.institution_name + '/' + user.user_name}</Grid>
          <Grid item>{selectMonth}</Grid>
        </Grid>
      </Box>
      {count === 0 ? (
        <p>データがありません</p>
      ) : (
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row">
                  項目
                </TableCell>
                <TableCell>実施日時</TableCell>
                <TableCell>スコア</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(checkData).flatMap(([name, data]) => {
                return (data as any[]).map(record => {
                  const date = record[CheckItemProperties[name as keyof CheckData].date];
                  const value = record[CheckItemProperties[name as keyof CheckData].value];
                  const unit = CheckItemProperties[name as keyof CheckData].unit;
                  const url = `/institutions/${institution.institution_sub}/check/${user.user_id}/${selectMonth}/${name}/${date}`;
                  const key = name + date;
                  let apiName = '';
                  if (name === 'balance_right' || name === 'balance_left') {
                    apiName = 'balance';
                  } else if (
                    name === 'rom_101_right' ||
                    name === 'rom_101_left' ||
                    name === 'rom_602_right' ||
                    name === 'rom_602_left'
                  ) {
                    apiName = 'rom';
                  } else {
                    apiName = name;
                  }
                  return (
                    <TableRow key={key}>
                      <TableCell component="td" scope="row">
                        {CheckNames[name as keyof CheckData]}
                      </TableCell>
                      <TableCell>{date}</TableCell>
                      <TableCell>
                        {value}
                        {unit}
                      </TableCell>
                      <TableCell align="right" width="10%">
                        {isSuper ? (
                          <Link to={url} style={{ textDecoration: 'none' }}>
                            <Button color="primary" variant="contained" size="large">
                              修正
                            </Button>
                          </Link>
                        ) : (
                          <Button color="primary" variant="contained" size="large" disabled={true}>
                            修正
                          </Button>
                        )}
                      </TableCell>
                      <TableCell align="right" width="10%">
                        <Button
                          color="secondary"
                          variant="contained"
                          size="large"
                          onClick={() => deleteMoffCheck(apiName as CheckAPIName, date)}
                          disabled={!isSuper}
                        >
                          削除
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                });
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default MoffCheckList;
