import { connect } from 'react-redux';
import UsersCreated from '../components/User/UsersCreated';
import { RootState } from '../store/configureStore';

function mapStateToProps(state: RootState) {
  return {
    user: state.user,
    institution: state.institution,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersCreated);
