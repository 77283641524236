import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import * as InstitutionActions from '../../actions/Institution';
import * as UserActions from '../../actions/User/actions';
import * as CheckActions from '../../actions/Check/actions';
import { RootState } from '../../store/configureStore';
import SelectMonth from '../../components/Check/SelectMonth';
import Loading from '../../components/common/Loading';
import BackButton from '../../components/common/BackButton';

import { FetchUserDataType } from '../../constants/User';
import { CheckAPINamesList, CheckDataNamesList } from '../../constants/Check';

const SelectMonthContainer: FC = () => {
  const dispatch = useDispatch();
  const institution = useSelector((state: RootState) => state.institution.institution);
  const users = useSelector((state: RootState) => state.user.listData);
  const check = useSelector((state: RootState) => state.check.data);
  const checkIsLoaded = useSelector((state: RootState) => state.check.loaded);
  const { institutionId, userId } = useParams<{ institutionId: string; userId: string }>();
  const [dateArr, setDateArr] = useState<string[]>([]);
  const [year, setYear] = useState(dayjs().get('year'));
  const [month, setMonth] = useState(dayjs().get('month') + 1);
  const [user, setUser] = useState(users.find((user: FetchUserDataType) => user.user_id === userId));

  useEffect(() => {
    if (!institution) {
      dispatch(InstitutionActions.get(institutionId));
    }
  }, [institution, institutionId, dispatch]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(UserActions.getUsersWithUniqueId(institutionId));
    }
  }, [users, institutionId, dispatch]);

  useEffect(() => {
    setUser(users.find((user: FetchUserDataType) => user.user_id === userId));
  }, [users, userId]);

  useEffect(() => {
    if (user) {
      dispatch(CheckActions.resetCheckData());
      dispatch(CheckActions.setUniqueId(user.unique_id));
      dispatch(CheckActions.getCheckDataByYear(CheckAPINamesList, user.unique_id, year, month));
    }
  }, [year, month, user, dispatch]);

  useEffect(() => {
    const arr: string[] = [];
    CheckDataNamesList.forEach(name => {
      Object.entries(check[name]).forEach(([yearMonth, data]) => {
        if ((data as any).length > 0 && !arr.includes(yearMonth)) {
          arr.push(yearMonth);
        }
      });
    });
    arr.sort((a, b) => (a < b ? -1 : 1));
    setDateArr(arr);
  }, [check]);

  const handleChangeForYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setYear(event.target.value as number);
  };

  const handleChangeForMonth = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMonth(event.target.value as number);
  };

  return user && institution && checkIsLoaded ? (
    <>
      <BackButton />
      <SelectMonth
        institution={institution}
        user={user}
        dateArr={dateArr}
        year={year}
        month={month}
        handleChangeForYear={handleChangeForYear}
        handleChangeForMonth={handleChangeForMonth}
      />
    </>
  ) : (
    <Loading />
  );
};

export default SelectMonthContainer;
