import { UserCareLevelMap, UserGenderMap, UserVisitMap } from '../../constants/User';
import csv from 'csv-parser';

const toNumber = (value: string): number => {
  if (value === '') {
    return NaN;
  }
  return Number(value);
};

export const convertUserData = (userRawData: { [s: string]: any }): { [s: string]: any } => {
  const userData: { [s: string]: any } = {};
  userData['user_name'] = userRawData['氏名'];
  userData['user_name_kana'] = userRawData['ふりがな'];
  userData['user_care_id'] = userRawData['介護保険番号'];
  userData['user_birth_year'] = toNumber(userRawData['誕生年']);
  userData['user_birth_month'] = toNumber(userRawData['誕生月']);
  userData['user_birth_day'] = toNumber(userRawData['誕生日']);
  userData['user_care_level'] = UserCareLevelMap[userRawData['要介護度']];
  userData['user_gender'] = UserGenderMap[userRawData['性別']];
  userData['user_visit_day'] = [
    UserVisitMap[userRawData['月']] || 0,
    UserVisitMap[userRawData['火']] || 0,
    UserVisitMap[userRawData['水']] || 0,
    UserVisitMap[userRawData['木']] || 0,
    UserVisitMap[userRawData['金']] || 0,
    UserVisitMap[userRawData['土']] || 0,
    UserVisitMap[userRawData['日']] || 0,
  ];
  userData['user_insurer_no'] = userRawData['保険者番号'];
  return userData;
};

export const userValidate = (user: { [s: string]: any }) => {
  const errors: { [s: string]: string } = {};
  // 氏名
  if (!user.user_name) {
    errors['user_name'] = '利用者名が入力されていません';
  }
  // ふりがな
  if (!user.user_name_kana) {
    errors['user_name_kana'] = 'ふりがなが入力されていません';
  } else {
    if (!user.user_name_kana.match(/^[\u3041-\u3096]+$/)) {
      errors['user_name_kana'] = 'ふりがなに平仮名以外が使用されています';
    }
  }
  // 介護被保険者番号
  if (!user.user_care_id) {
    errors['user_care_id'] = '介護被保険者番号が入力されていません';
  } else {
    if (!user.user_care_id.match(/^[0-9]+$/)) {
      errors['user_care_id'] = '介護被保険者番号に半角数字以外が使用されています';
    }
  }
  // 生年月日
  const year = user.user_birth_year;
  const month = user.user_birth_month;
  const day = user.user_birth_day;
  if (!Number.isInteger(year)) {
    errors['user_birth_year'] = '生年月日の年が不正です';
  }
  if (!Number.isInteger(month)) {
    errors['user_birth_month'] = '生年月日の月が不正です';
  }
  if (!Number.isInteger(day)) {
    errors['user_birth_day'] = '生年月日の日が不正です';
  }
  if (Number.isInteger(year) && Number.isInteger(month) && Number.isInteger(day)) {
    const birthDay = new Date(user.user_birth_year, user.user_birth_month - 1, user.user_birth_day);
    if (
      user.user_birth_year !== birthDay.getFullYear() ||
      user.user_birth_month - 1 !== birthDay.getMonth() ||
      user.user_birth_day !== birthDay.getDate()
    ) {
      errors['user_combined_birth'] = '存在しない日付です';
    }
  }
  // 性別
  if (user.user_gender == null) {
    errors['user_gender'] = '性別が入力されていません';
  }
  // 要介護度
  if (user.user_care_level == null) {
    errors['user_care_level'] = '要介護度が入力されていません';
  }
  // 訪問日
  if (user.user_visit_day.length !== 7) {
    errors['user_visit_day'] = '訪問日が正しく入力されていません';
  }
  // 保険者番号 (任意)
  // if (!user.user_insurer_no) {
  //   errors['user_insurer_no'] = '保険者番号が入力されていません';
  // }
  return errors;
};

export const readTextAsPromise = (file: File): Promise<string> => {
  return new Promise<string>((resoleve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => reject(reader.error);
    reader.onload = () => {
      let result = (reader.result as string) || '';
      if (result.substr(-1, 1) !== '\n') {
        result = result + '\n';
      }
      resoleve(result);
    };
    reader.readAsText(file);
  });
};

export const loadUsers = async (file: File) => {
  const stream = csv();
  const prePostData: { [s: string]: any }[] = [];
  const invalidPrePostData: { [s: string]: any }[] = [];

  stream.on('data', data => {
    const userData = convertUserData(data);
    const errors = userValidate(userData);
    if (!Object.keys(errors).length) {
      prePostData.push(userData);
    } else {
      userData['errors'] = errors;
      invalidPrePostData.push(userData);
    }
  });

  const data = await readTextAsPromise(file);
  stream.write(data);
  return {
    prePostData,
    invalidPrePostData,
  };
};
