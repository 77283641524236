import React, { FC } from 'react';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
} from '@material-ui/core';
import {
  CheckDataName,
  CheckItemDisplay,
  CheckNames,
  CheckDataNamesList,
  RecordedAtNamesList,
} from '../../constants/Check';

interface MoffCheckFormProps {
  selectItem: CheckDataName;
  datetime: string;
  valueObj: { [key: string]: string };
  itemHandleChange?: (event: React.ChangeEvent<{ value: CheckDataName | unknown }>) => void;
  dateHandleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  valueHandleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MoffCheckForm: FC<MoffCheckFormProps> = ({
  selectItem,
  datetime,
  valueObj,
  itemHandleChange,
  dateHandleChange,
  valueHandleChange,
}) => {
  const displays = Object.entries(CheckItemDisplay[selectItem]);
  const dateInput = RecordedAtNamesList.includes(selectItem);

  return (
    <TableContainer>
      {itemHandleChange ? (
        <Box my={2} display="flex" alignItems="center">
          <Box mx={2} component="span" fontWeight="fontWeightBold">
            項目
          </Box>
          <FormControl style={{ minWidth: 200 }}>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={selectItem}
              onChange={itemHandleChange}
              data-testid="item-select"
            >
              {CheckDataNamesList.map((item, i) => {
                return (
                  <MenuItem key={i} value={item}>
                    {CheckNames[item]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      ) : null}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>実施日時</TableCell>
            {displays.map(([field, disp], i) => {
              return (
                <TableCell key={i} component="th" scope="row">
                  {disp.name}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <TextField
                id="outlined-basic"
                type={dateInput ? 'date' : 'datetime-local'}
                variant="outlined"
                value={datetime}
                onChange={dateHandleChange}
                inputProps={
                  dateInput
                    ? undefined
                    : {
                        step: '1',
                      }
                }
                data-testid="date-input"
              />
            </TableCell>
            {displays.map(([field, disp], i) => {
              return (
                <TableCell key={i} component="th" scope="row">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name={field}
                    value={valueObj[field]}
                    onChange={valueHandleChange}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{disp.unit}</InputAdornment>,
                    }}
                    data-testid="value-input"
                  />
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MoffCheckForm;
