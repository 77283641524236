import { UserActionTypes, FetchUserDataType } from '../../constants/User';

export const initialState = {
  listData: [] as FetchUserDataType[],
  prePostData: [],
  invalidPrePostData: [],
  postSucceededData: [],
  postFailedData: [],
  idle: true,
  executing: false,
  csvLoaded: false,
  error: null,
};

export default function user(state = initialState, action: any) {
  switch (action.type) {
    case UserActionTypes.START_FETCH:
      return Object.assign({}, state, {
        idle: false,
        executing: true,
        error: null,
      });

    case UserActionTypes.END_FETCH:
      return Object.assign({}, state, {
        listData: action.listData,
        idle: true,
        executing: false,
      });

    case UserActionTypes.API_ERROR:
      return Object.assign({}, state, {
        listData: [],
        idle: true,
        executing: false,
        error: action.error,
      });

    case UserActionTypes.START_CREATE:
      return Object.assign({}, state, {
        idle: false,
        executing: true,
      });

    case UserActionTypes.END_CREATE:
      return Object.assign({}, state, {
        idle: true,
        executing: false,
        postSucceededData: action.postSucceededData,
        postFailedData: action.postFailedData,
      });

    case UserActionTypes.START_CSV_LOAD:
      return Object.assign({}, state, {
        prePostData: [],
        invalidPrePostData: [],
        csvLoaded: false,
      });

    case UserActionTypes.END_CSV_LOAD:
      return Object.assign({}, state, {
        prePostData: action.prePostData,
        invalidPrePostData: action.invalidPrePostData,
        csvLoaded: true,
      });

    case UserActionTypes.CSV_LOAD_ERROR:
      return Object.assign({}, state, {
        csvLoaded: false,
        error: action.error,
      });

    default:
      return state;
  }
}
