import * as AWS from 'aws-sdk';
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';

const Config = AWS.config;

const authenticationDetail = (username: string, password: string) => {
  return new AuthenticationDetails({
    Username: username,
    Password: password,
  });
};

const userPool = () => {
  return new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID || '',
    ClientId: process.env.REACT_APP_AWS_USER_CLIENT_ID || '',
  });
};

const cognitoUser = (username: any) => {
  return new CognitoUser({
    Username: username,
    Pool: userPool(),
  });
};

const cognitoIdpURL =
  'cognito-idp.' + process.env.REACT_APP_AWS_REGION + '.amazonaws.com/' + process.env.REACT_APP_AWS_USER_POOL_ID;

const institutionPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_AWS_INSTITUTION_POOL_ID || '',
  ClientId: process.env.REACT_APP_AWS_INSTITUTION_CLIENT_ID || '',
});

export { Config, userPool, cognitoUser, authenticationDetail, cognitoIdpURL, institutionPool };
