import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { CheckAPIName } from '../../constants/Check';
import * as AWSconst from '../../constants/AWS';
import * as AWS from 'aws-sdk';
import aws4Interceptor from 'aws4-axios';
import axios from 'axios';
import {
  BadRequestError,
  InternalServerError,
  NotFoundError,
  UnauthorizedError,
  ConflictError,
} from '../../constants/API';
import { asyncGetSession } from '../Auth/api';
import { RecordedAtNamesList } from '../../constants/Check';

const urlBuilderGET = (name: CheckAPIName, unique_id: string, year: number, month: number, byYear = false): string => {
  let from_date = '';
  let to_date = '';
  if (byYear) {
    from_date = `${year - 1}-${('0' + month).slice(-2)}-01`;
    to_date = `${year}-${('0' + month).slice(-2)}-${new Date(year, month, 0).getDate()}`;
  } else {
    from_date = `${year}-${('0' + month).slice(-2)}-01`;
    to_date = `${year}-${('0' + month).slice(-2)}-${new Date(year, month, 0).getDate()}`;
  }
  let url = '';
  switch (name) {
    case 'tug':
      url = `/admin/walking/tug/${unique_id}/records/from/${from_date}/to/${to_date}`;
      break;
    case 'cs30':
      url = `/admin/chairstand/${unique_id}/records/from/${from_date}/to/${to_date}`;
      break;
    case 'balance':
      url = `/admin/balance/${unique_id}/records/from/${from_date}/to/${to_date}`;
      break;
    case 'rom':
      url = `/admin/rom/${unique_id}/records/from/${from_date}/to/${to_date}`;
      break;
    case 'ss5':
      url = `/admin/ss5/${unique_id}/records/from/${from_date}/to/${to_date}`;
      break;
    case 'grip_right':
      url = `/admin/physical_record/${unique_id}/records/0/from/${from_date}/to/${to_date}`;
      break;
    case 'grip_left':
      url = `/admin/physical_record/${unique_id}/records/1/from/${from_date}/to/${to_date}`;
      break;
    case 'height':
      url = `/admin/physical_record/${unique_id}/records/2/from/${from_date}/to/${to_date}`;
      break;
    case 'weight':
      url = `/admin/physical_record/${unique_id}/records/3/from/${from_date}/to/${to_date}`;
      break;
    default:
      throw Error(`${name} is not exist`);
  }
  return url;
};

const urlBuilderPOST = (name: CheckAPIName, unique_id: string): string => {
  let url = '';
  switch (name) {
    case 'tug':
      url = `/admin/walking/tug/${unique_id}/records`;
      break;
    case 'cs30':
      url = `/admin/chairstand/${unique_id}/records`;
      break;
    case 'balance':
      url = `/admin/balance/${unique_id}/records`;
      break;
    case 'rom':
      url = `/admin/rom/${unique_id}/records`;
      break;
    case 'ss5':
      url = `/admin/ss5/${unique_id}/records`;
      break;
    case 'grip_right':
      url = `/admin/physical_record/${unique_id}/records/0`;
      break;
    case 'grip_left':
      url = `/admin/physical_record/${unique_id}/records/1`;
      break;
    case 'height':
      url = `/admin/physical_record/${unique_id}/records/2`;
      break;
    case 'weight':
      url = `/admin/physical_record/${unique_id}/records/3`;
      break;
    default:
      throw Error(`${name} is not exist`);
  }
  return url;
};

const urlBuilderPUT = (name: CheckAPIName, unique_id: string, date: string): string => {
  let url = '';
  switch (name) {
    case 'tug':
      url = `/admin/walking/tug/${unique_id}/records/${date}`;
      break;
    case 'cs30':
      url = `/admin/chairstand/${unique_id}/records/${date}`;
      break;
    case 'balance':
      url = `/admin/balance/${unique_id}/records/${date}`;
      break;
    case 'rom':
      url = `/admin/rom/${unique_id}/records/${date}`;
      break;
    case 'ss5':
      url = `/admin/ss5/${unique_id}/records/${date}`;
      break;
    case 'grip_right':
      url = `/admin/physical_record/${unique_id}/records/0/${date}`;
      break;
    case 'grip_left':
      url = `/admin/physical_record/${unique_id}/records/1/${date}`;
      break;
    case 'height':
      url = `/admin/physical_record/${unique_id}/records/2/${date}`;
      break;
    case 'weight':
      url = `/admin/physical_record/${unique_id}/records/3/${date}`;
      break;
    default:
      throw Error(`${name} is not exist`);
  }
  return url;
};

const urlBuilderSwapPUT = (name: CheckAPIName, unique_id: string): string => {
  let url = '';
  switch (name) {
    case 'tug':
      url = `/admin/walking/tug/${unique_id}/records`;
      break;
    case 'cs30':
      url = `/admin/chairstand/${unique_id}/records`;
      break;
    case 'balance':
      url = `/admin/balance/${unique_id}/records`;
      break;
    case 'rom':
      url = `/admin/rom/${unique_id}/records`;
      break;
    case 'ss5':
      url = `/admin/ss5/${unique_id}/records`;
      break;
    case 'grip_right':
      url = `/admin/physical_record/${unique_id}/records`;
      break;
    case 'grip_left':
      url = `/admin/physical_record/${unique_id}/records`;
      break;
    case 'height':
      url = `/admin/physical_record/${unique_id}/records`;
      break;
    case 'weight':
      url = `/admin/physical_record/${unique_id}/records`;
      break;
    default:
      throw Error(`${name} is not exist`);
  }
  return url;
};

const urlBuilderDELETE = (name: CheckAPIName, unique_id: string, date: string): string => {
  let url = '';
  switch (name) {
    case 'tug':
      url = `/admin/walking/tug/${unique_id}/records/${date}`;
      break;
    case 'cs30':
      url = `/admin/chairstand/${unique_id}/records/${date}`;
      break;
    case 'balance':
      url = `/admin/balance/${unique_id}/records/${date}`;
      break;
    case 'rom':
      url = `/admin/rom/${unique_id}/records/${date}`;
      break;
    case 'ss5':
      url = `/admin/ss5/${unique_id}/records/${date}`;
      break;
    case 'grip_right':
      url = `/admin/physical_record/${unique_id}/records/0/${date}`;
      break;
    case 'grip_left':
      url = `/admin/physical_record/${unique_id}/records/1/${date}`;
      break;
    case 'height':
      url = `/admin/physical_record/${unique_id}/records/2/${date}`;
      break;
    case 'weight':
      url = `/admin/physical_record/${unique_id}/records/3/${date}`;
      break;
    default:
      throw Error(`${name} is not exist`);
  }
  return url;
};

export class CheckAdminClient {
  client: AxiosInstance;
  baseURL: string;

  static async ClientFactory() {
    const user = AWSconst.userPool().getCurrentUser();
    if (!user) {
      throw Error('user not signed in');
    }
    const session = await asyncGetSession(user);
    AWS.config.region = process.env.REACT_APP_AWS_REGION;
    const credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID || '',
      Logins: {
        [AWSconst.cognitoIdpURL]: session.getIdToken().getJwtToken(),
      },
    });
    await credentials.getPromise();
    const interceptor = aws4Interceptor(
      {
        region: AWS.config.region,
        service: 'execute-api',
      },
      {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
    );
    return new CheckAdminClient(interceptor);
  }

  constructor(intercepter: (config: AxiosRequestConfig) => AxiosRequestConfig) {
    this.baseURL = process.env.REACT_APP_CHECK_API_URL || '';
    this.client = axios.create({
      baseURL: this.baseURL,
    });
    this.client.interceptors.request.use(intercepter);
    this.client.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        const response = error.response;
        if (response === undefined) {
          return Promise.reject(error);
        }
        if (response.status === 400) {
          return Promise.reject(new BadRequestError(response.data.message));
        } else if (response.status === 403) {
          return Promise.reject(new UnauthorizedError(response.data.message));
        } else if (response.status === 404) {
          return Promise.reject(new NotFoundError(response.data.message));
        } else if (response.status === 409) {
          return Promise.reject(new ConflictError(response.data.message));
        } else if (response.status >= 500) {
          return Promise.reject(new InternalServerError(response.status, response.data.message));
        } else {
          return Promise.reject(error);
        }
      },
    );
  }

  async getDataByMonth(
    name: CheckAPIName,
    unique_id: string,
    year: number,
    month: number,
  ): Promise<{ [s: string]: any }> {
    const url = urlBuilderGET(name, unique_id, year, month);
    const res = await this.client.get(url);
    return { [name]: res.data.Items };
  }

  async getDataByYear(
    name: CheckAPIName,
    unique_id: string,
    year: number,
    month: number,
  ): Promise<{ [s: string]: any }> {
    const url = urlBuilderGET(name, unique_id, year, month, true);
    const res = await this.client.get(url);
    return { [name]: res.data['Items'] };
  }

  async postData(name: CheckAPIName, unique_id: string, data: { [s: string]: any }): Promise<void> {
    const url = urlBuilderPOST(name, unique_id);
    await this.client.post(url, data, { headers: { 'content-type': 'application/json;charset=UTF-8' } });
  }

  async putData(
    name: CheckAPIName,
    unique_id: string,
    date: string,
    data: { [s: string]: any },
    change_started_at: boolean,
  ): Promise<void> {
    const url = urlBuilderPUT(name, unique_id, date);
    const queryParametersKey = RecordedAtNamesList.includes(name) ? 'change_recorded_at' : 'change_started_at';

    await this.client.put(url, data, {
      headers: { 'content-type': 'application/json;charset=UTF-8' },
      params: { [queryParametersKey]: change_started_at },
    });
  }

  async swapData(name: CheckAPIName, unique_id: string, data: { [s: string]: any }): Promise<void> {
    const url = urlBuilderSwapPUT(name, unique_id);
    await this.client.put(url, data, { headers: { 'content-type': 'application/json;charset=UTF-8' } });
  }

  async deleteData(name: CheckAPIName, unique_id: string, date: string): Promise<void> {
    const url = urlBuilderDELETE(name, unique_id, date);
    await this.client.delete(url);
  }
}
