import * as Alert from '../../constants/Alert';

const initialState = {
  displayError: false,
  alertTitle: '',
  alertMessage: '',
  onClickEvent: null,
};

export default function alert(state = initialState, action: any) {
  switch (action.type) {
    case Alert.DISPLAY_ALERT:
      return Object.assign({}, state, {
        displayError: 'true',
        alertTitle: action.title,
        alertMessage: action.message,
        onClickEvent: null,
      });
    case Alert.CLOSE_ALERT:
      return Object.assign({}, state, {
        displayError: 'false',
        alertTitle: '',
        alertMessage: '',
        onClickEvent: null,
      });
    default:
      return state;
  }
}
