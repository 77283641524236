import React, { Component } from 'react';
import CSVLoader from './CSVLoader';
import UsersList from './UsersList';
import PostUsersButton from './PostUsersButton';
import Loading from '../common/Loading';
import { Box, Grid, Container } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';

export interface StateProps {
  user: any;
  institution: any;
  match: any;
}

export interface DispatchProps {
  userActions: any;
  institutionActions: any;
}

export interface OwnProps extends RouteComponentProps<{}> {}

type Props = StateProps & DispatchProps & OwnProps;

export default class UsersNew extends Component<Props> {
  institutionSub: string;

  constructor(props: any) {
    super(props);
    const institutionSub = this.props.match.params.institution_sub;
    this.institutionSub = institutionSub;
  }

  componentDidMount() {
    if (this.props.institution.institution === null) {
      this.props.institutionActions.get(this.institutionSub);
    }
    this.props.userActions.getUsers(this.institutionSub);
    this.props.userActions.resetCSVLoading();
  }

  render() {
    const csvLoaderDisable = this.props.user.listData.length > 0 || this.props.user.executing;
    const postUsersButtonDisable =
      this.props.user.invalidPrePostData.length > 0 ||
      this.props.user.prePostData.length === 0 ||
      this.props.user.executing;
    const isFetching = this.props.institution.institution === null || this.props.user.executing;

    if (isFetching) {
      return <Loading />;
    } else if (this.props.user.error) {
      return <p>{this.props.user.error.message}</p>;
    } else {
      return (
        <Container maxWidth="md">
          <Box m={3}>
            <h2>{this.props.institution.institution.institution_name}</h2>
            <p>上記施設利用者の一括登録を行います。</p>
            <CSVLoader loadUsers={this.props.userActions.loadUsersFromCSV} isDisable={csvLoaderDisable} />
            {this.props.user.listData.length > 0 ? (
              <p>すでに利用者が登録されているため、一括登録は利用できません。</p>
            ) : (
              <p>*「UTF-8」でエンコードされたCSVファイルを利用してください。</p>
            )}
            {this.props.user.csvLoaded ? (
              <UsersList
                users={this.props.user.prePostData}
                invalidUsers={this.props.user.invalidPrePostData}
                withLink={false}
              />
            ) : (
              ''
            )}
            {this.props.user.csvLoaded ? (
              <Grid container justify="flex-start">
                <Box>
                  {postUsersButtonDisable ? (
                    <p>データが正しく入力されていない利用者が存在しています。</p>
                  ) : (
                    <p>上記内容で利用者を登録します。</p>
                  )}
                  <PostUsersButton
                    users={this.props.user.prePostData}
                    createUsers={this.props.userActions.createUsers}
                    institutionSub={this.institutionSub}
                    isDisable={postUsersButtonDisable}
                    history={this.props.history}
                  />
                </Box>
              </Grid>
            ) : (
              ''
            )}
          </Box>
        </Container>
      );
    }
  }
}
