import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import app from './reducers/App';
import auth from './reducers/Auth';
import toggle from './reducers/Toggle';
import institution from './reducers/Institution';
import user from './reducers/User';
import alert from './reducers/Alert';
import error from './reducers/Error';
import check from './reducers/Check';
import { composeWithDevTools } from 'redux-devtools-extension';

import { reducer as formReducer } from 'redux-form';

export const history = createBrowserHistory();
const enhancer =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(thunkMiddleware, routerMiddleware(history)))
    : applyMiddleware(thunkMiddleware, routerMiddleware(history));

export const rootReducer = combineReducers({
  app: app,
  auth: auth,
  toggle: toggle,
  institution: institution,
  user: user,
  alert: alert,
  error: error,
  form: formReducer,
  check: check,
  router: connectRouter(history),
});

export type RootState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  return createStore(rootReducer, enhancer);
}
