import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Box, Toolbar, IconButton, MenuItem, Drawer, Button, Typography, Link } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

interface HeaderProps extends React.Props<{}> {
  auth: any;
  toggle: any;
  toggleActions: any;
  authActions: any;
  classes: any;
}

const styles = {
  root: {
    flexGrow: 1,
  },
  drawerElement: {
    width: 200,
    padding: 15,
    flexShrink: 0,
  },
  title: {
    flexGrow: 1,
    marginLeft: 20,
  },
};

class Header extends Component<HeaderProps, {}> {
  labels = {
    signIn: 'サインイン',
    signOut: 'サインアウト',
  };

  handleToggle = () => {
    if (this.props.toggle.isOn) {
      this.props.toggleActions.toggleOff();
    } else {
      this.props.toggleActions.toggleOn();
    }
  };

  handleSignOut = () => {
    this.props.authActions.signOut();
  };

  render() {
    return (
      <header>
        <AppBar position="static" color="primary">
          <Toolbar>
            <IconButton
              edge="start"
              className={this.props.classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={this.handleToggle}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h5" className={this.props.classes.title}>
              Moffトレ 管理
            </Typography>
            {this.props.auth.isAuthenticated ? (
              <Button color="inherit" onClick={this.handleSignOut}>
                {this.labels.signOut}
              </Button>
            ) : (
              <Button color="inherit">{this.labels.signIn}</Button>
            )}
          </Toolbar>
        </AppBar>
        <Drawer open={this.props.toggle.isOn} onClose={this.handleToggle}>
          {this.props.auth.isAuthenticated ? (
            <Box>
              <Link href="/institutions/list" color="inherit" underline="none">
                <MenuItem key={1} className={this.props.classes.drawerElement}>
                  施設情報一覧
                </MenuItem>
              </Link>
            </Box>
          ) : (
            <Box>
              <Link href="/institutions/list" color="inherit" underline="none">
                <MenuItem className={this.props.classes.drawerElement}>{this.labels.signIn}</MenuItem>
              </Link>
            </Box>
          )}
        </Drawer>
      </header>
    );
  }
}

export default withStyles(styles)(Header);
