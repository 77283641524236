import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Box, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles({
  backButton: {
    position: 'absolute',
    top: '80px',
    left: '20px',
  },
});

const BackButton = () => {
  const classes = useStyles();
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Box component="span" className={classes.backButton}>
      <Button onClick={goBack}>
        <ArrowBackIcon />
      </Button>
    </Box>
  );
};

export default BackButton;
