import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Box, TextField } from '@material-ui/core';
// eslint-disable-next-line no-unused-vars
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

interface SignFormProps extends InjectedFormProps<{}> {
  authActions: any;
  auth: any;
  signForm: any;
  reset: any;
}

const validate = (values: any) => {
  const errors: any = {};
  const requiredFields = ['username', 'password'];
  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = `${field === 'username' ? 'アカウント' : 'パスワード'}を入力してください`;
    }
  });
  return errors;
};

class SignForm extends Component<SignFormProps, {}> {
  labels = {
    accountName: 'アカウント名',
    password: 'パスワード',
    signIn: 'サインイン',
    clear: 'クリア',
  };

  handleSubmit = () => {
    this.props.authActions.signIn(this.props.signForm.username, this.props.signForm.password);
  };

  handleReset = () => {
    this.props.reset();
  };

  renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }: any) => {
    return (
      <TextField
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        fullWidth
        {...input}
        {...custom}
      />
    );
  };

  render() {
    if (this.props.auth.isAuthenticated === true) {
      return <Redirect to={'/institutions/list'} />;
    }
    const wellStyles = { maxWidth: 400, margin: '0 auto 10px' };

    return (
      <form style={wellStyles}>
        <Box mt={3}>
          <Field name="username" component={this.renderTextField} label={this.labels.accountName} autoFocus />
        </Box>
        <Box mt={2}>
          <Field name="password" component={this.renderTextField} label={this.labels.password} type="password" />
        </Box>
        <Box mt={3} px={8} display="flex" alignItems="center" justifyContent="space-evenly">
          <Button
            variant="contained"
            color="secondary"
            disabled={this.props.pristine || this.props.submitting || this.props.invalid}
            onClick={() => this.handleSubmit()}
          >
            {this.labels.signIn}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={this.props.pristine || this.props.submitting}
            onClick={() => this.handleReset()}
          >
            {this.labels.clear}
          </Button>
        </Box>
      </form>
    );
  }
}

export default reduxForm<{}, any>({
  form: 'signForm',
  validate,
})(SignForm);
