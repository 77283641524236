import { DayCareApi, DayCareApiIamAuth } from './BaseAPI';

export function requestGetInstitutionById(institutionSub: any, requestFunc: any, fetchedFunc: any) {
  return async (dispatch: any) => {
    dispatch(requestFunc());
    const client = await DayCareApi.ClientFactory();
    client.api
      .get('/admin/institutions/' + institutionSub)
      .then((response: any) => dispatch(fetchedFunc(true, response.data)))
      .catch((err: any) => {
        console.log(err);
        dispatch(fetchedFunc(false, err));
      });
  };
}

export function requestGetInstitutions(requestFunc: any, fetchedFunc: any) {
  return async (dispatch: any) => {
    dispatch(requestFunc());
    const client = await DayCareApi.ClientFactory();
    client.api
      .get('/institutions')
      .then((response: any) => {
        dispatch(fetchedFunc(true, response.data));
      })
      .catch((err: any) => {
        console.log(err);
        dispatch(fetchedFunc(false, err));
      });
  };
}

/**
 * 施設情報の更新.
 * ※Cognitoも更新されます.
 *
 * @export
 * @param {*} institutionSub
 * @param {*} body
 * @param {*} requestFunc
 * @param {*} fetchedFunc
 * @returns
 */
export function requestUpdateInstitution(institutionSub: any, body: any, requestFunc: any, fetchedFunc: any) {
  return async (dispatch: any) => {
    dispatch(requestFunc());
    const client = await DayCareApiIamAuth.ClientFactory();
    client.api
      .put('/institutions/' + institutionSub, body, { headers: { 'content-type': 'application/json;charset=UTF-8' } })
      .then((response: any) => dispatch(fetchedFunc(true, response.data)))
      .catch((err: any) => {
        console.log(err);
        dispatch(fetchedFunc(false, err));
      });
  };
}

/**
 * ID(institution_id)指定してEnable
 *
 * @export
 * @param {*} institutionId
 * @param {*} requestFunc
 * @param {*} fetchedFunc
 * @returns
 */
export function requestEnableInstitutionById(institutionId: any, requestFunc: any, fetchedFunc: any) {
  return async (dispatch: any) => {
    dispatch(requestFunc());
    const client = await DayCareApiIamAuth.ClientFactory();
    client.api
      .post(
        '/institutions/' + institutionId + '/enable',
        {},
        { headers: { 'content-type': 'application/json;charset=UTF-8' } },
      )
      .then((response: any) => dispatch(fetchedFunc(true, response.data)))
      .catch((err: any) => {
        console.log(err);
        dispatch(fetchedFunc(false, err));
      });
  };
}

/**
 * ID(institution_id)指定してDisable
 *
 * @export
 * @param {*} institutionId
 * @param {*} requestFunc
 * @param {*} fetchedFunc
 * @returns
 */
export function requestDisableInstitutionById(institutionId: any, requestFunc: any, fetchedFunc: any) {
  return async (dispatch: any) => {
    dispatch(requestFunc());
    const client = await DayCareApiIamAuth.ClientFactory();
    client.api
      .post(
        '/institutions/' + institutionId + '/disable',
        {},
        { headers: { 'content-type': 'application/json;charset=UTF-8' } },
      )
      .then((response: any) => dispatch(fetchedFunc(true, response.data)))
      .catch((err: any) => {
        console.log(err);
        dispatch(fetchedFunc(false, err));
      });
  };
}
