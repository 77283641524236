import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';

export interface StateProps {
  institution: any;
  auth: any;
}

export interface DispatchProps {
  institutionActions: any;
}

export interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

export default class InstitutionsList extends Component<Props> {
  componentDidMount = () => {
    this.props.institutionActions.loadList();
  };

  css_class = {
    paddingLeft: 2,
    paddingRight: 2,
    whiteSpace: 'normal',
    wordWrap: 'break-wor',
    fontSize: '17px',
  };

  button_label_css_class = {
    fontSize: '17px',
  };

  // タイトルは色だけ違う.
  css_class_title = Object.assign({}, this.css_class, { color: '#000080' });

  render() {
    const institutionsList = this.props.institution.listData;
    let institutions = null;
    if (institutionsList) {
      institutions = Object.keys(institutionsList).map(key => {
        // institution_idではないことに注意.
        const sub = institutionsList[key].institution_sub;
        // こっちは詳細.
        const institutionUrl = '/institutions/detail?id=' + sub;

        return (
          <TableRow key={key} hover>
            <TableCell>{institutionsList[key].institution_name}</TableCell>
            <TableCell>{institutionsList[key].company_name}</TableCell>
            <TableCell>
              <Link to={institutionUrl}>{institutionsList[key].institution_id}</Link>
            </TableCell>
            <TableCell>{institutionsList[key].application_year_month}</TableCell>
            <TableCell>{institutionsList[key].institution_email}</TableCell>
            <TableCell>{institutionsList[key].institution_phone}</TableCell>
            <TableCell>
              {institutionsList[key].enabled ? <>有</> : <div style={{ color: 'lightgray' }}>無</div>}
            </TableCell>
            <TableCell>{institutionsList[key].created_at}</TableCell>

            <TableCell>{institutionsList[key].updated_at}</TableCell>
          </TableRow>
        );
      });
    } else {
      institutions = <CircularProgress size={200} thickness={15} />;
    }

    return (
      <div>
        <Box mt={3} ml={3}>
          <Link to={'/institutions/create'} style={{ textDecoration: 'none' }}>
            <Button color="primary" variant="contained" size="large" disabled={!this.props.auth.isSuperUser}>
              新規登録
            </Button>
          </Link>
        </Box>
        <Box p={3}>
          <TableContainer style={{ maxHeight: '100vh' }}>
            <Table stickyHeader size="medium">
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    施設名
                  </TableCell>
                  <TableCell>法人名</TableCell>
                  <TableCell style={{ width: 150 }}>事業所番号(ログインID)</TableCell>
                  <TableCell>入会月</TableCell>
                  <TableCell>メールアドレス</TableCell>
                  <TableCell>電話番号</TableCell>
                  <TableCell style={{ width: 30 }}>有/無</TableCell>
                  <TableCell style={{ width: 100 }}>登録日</TableCell>
                  <TableCell style={{ width: 100 }}>更新日</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{institutions ? institutions : <CircularProgress size={200} thickness={15} />}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    );
  }
}
