import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

export interface StateProps {
  error: any;
}

export interface DispatchProps {
  errorActions: any;
}

export interface OwnProps {
  title: string;
  message: string;
}

type Props = StateProps & DispatchProps & OwnProps;

/**
 * エラーのダイアログ.
 * ※ <Error title={'タイトル'} message={'内容'} /> で使用する.
 *
 * @class Error
 * @extends {React.Component}
 */
class Error extends React.Component<Props> {
  handleClose = () => {
    this.props.errorActions.close();
  };

  // TODO: componentWillMountはUNSAFEのため修正の必要あり
  // eslint-disable-next-line react/no-deprecated
  componentWillMount = () => {
    // まず表示.
    this.props.errorActions.alert(this.props.title, this.props.message);
  };

  getErrorMessage(err: any) {
    if (err === 'User already exists') {
      return 'この介護保険事業所番号は既に登録されています。';
    }

    return err;
  }

  render() {
    return (
      <Dialog open={this.props.error.displayError}>
        <DialogTitle id="alert-dialog-title">{this.props.error.errorTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.getErrorMessage(this.props.error.errorMessage)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default Error;
