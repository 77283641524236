import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import App from '../components/App';
import * as AuthActions from '../actions/Auth/actions';
import { RootState } from '../store/configureStore';
import { Dispatch } from 'redux';

function mapStateToProps(state: RootState) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    authActions: bindActionCreators(AuthActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
