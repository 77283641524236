import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import * as InstitutionActions from '../actions/Institution';
import InstitutionUpdateForm from '../components/InstitutionUpdateForm';
import { Model } from '../constants/Institution';

const selector = formValueSelector('institutionUpdateForm');

function mapStateToProps(state: any) {
  const institution_name = selector(state, 'institution_name');
  const institution_name_kana = selector(state, 'institution_name_kana');
  const company_name = selector(state, 'company_name');
  const company_name_kana = selector(state, 'company_name_kana');
  const institution_phone = selector(state, 'institution_phone');
  const institution_email = selector(state, 'institution_email');
  const institution_care_id = selector(state, 'institution_care_id');
  const post_code = selector(state, 'post_code');
  const address = selector(state, 'address');
  const fax = selector(state, 'fax');
  const Administrator = selector(state, 'Administrator');
  const ContactPerson = selector(state, 'ContactPerson');
  const iPadCode = selector(state, 'iPadCode');
  const plan_type = selector(state, 'plan_type');
  const enabled = selector(state, 'enabled');

  let initialValues = null;
  if (state.institution.institution) {
    // 更に中のinstitutionに格納されている.
    initialValues = state.institution.institution;
  }

  return {
    institution: state.institution,
    // フォームの初期値.
    initialValues: initialValues,
    // バリデーション.
    validate: validate,
    // 更新されるフォームのキー達.
    // ※「アカウントID(事業所番号)」と「入会年月」は変わらないためここに無い.
    institutionUpdateForm: {
      institution_name,
      institution_name_kana,
      company_name,
      company_name_kana,
      institution_phone,
      institution_email,
      institution_care_id,
      post_code,
      address,
      fax,
      Administrator,
      ContactPerson,
      iPadCode,
      plan_type,
      enabled,
    },
    auth: state.auth,
  };
}

// バリデーションチェック.
const validate = (values: any) => {
  const errors: { [s: string]: string } = {};

  // 必須項目.
  const requiredFields = ['institution_name', 'institution_name_kana', 'company_name', 'company_name_kana'];

  requiredFields.forEach((field: string) => {
    if (!values[field]) {
      errors[field] = `${Model[field]}が未入力です。`;
    }
  });

  // メールアドレス.
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.institution_email)) {
    errors.institution_email = `${Model['institution_email']}を正しく入力してください。`;
  }

  const isKatakana = (str: string) => {
    str = str == null ? '' : str;
    // eslint-disable-next-line no-irregular-whitespace
    if (str.match(/^[ァ-ヶー　]*$/)) {
      return true;
    } else {
      return false;
    }
  };

  // カタカナ項目.
  const katakanaFields = ['institution_name_kana', 'company_name_kana'];

  // カタカナ.
  katakanaFields.forEach(field => {
    if (!isKatakana(values[field])) {
      errors[field] = `${Model[field]}を正しく入力してください。`;
    }
  });

  // 契約プラン.
  const validPlan = ['Regular', 'Light', 'MoffCheck'];

  // 契約プラン
  let invalidPlanFlag = true;
  validPlan.forEach(field => {
    if (field === values['plan_type']) invalidPlanFlag = false;
  });

  if (invalidPlanFlag) {
    errors.plan_type = `契約プランを正しく入力してください。`;
  }
  return errors;
};

function mapDispatchToProps(dispatch: any) {
  return {
    institutionActions: bindActionCreators(InstitutionActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionUpdateForm);
