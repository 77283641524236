import { Container, Box, Button } from '@material-ui/core';
import React, { Component } from 'react';
import Loading from '../common/Loading';
import UsersList from './UsersList';

export interface StateProps {
  user: any;
  institution: any;
}

export interface DispatchProps {}

export interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

export default class UsersCreated extends Component<Props> {
  render() {
    if (this.props.user.executing) {
      return <Loading />;
    }

    if (this.props.user.error === 'ALREADY_REGISTERED') {
      return (
        <Container maxWidth="md">
          <Box m={3}>
            <h2>{this.props.institution.institution.institution_name}</h2>
            <p>
              他の端末から利用者が登録されたため、一括登録を実行できませんでした。
              システム管理者に問い合わせてください。
            </p>
            <Button color="primary" variant="contained" size="large" href={'/'}>
              施設一覧に戻る
            </Button>
          </Box>
        </Container>
      );
    }

    const succeeded = this.props.user.postSucceededData.length > 0 && this.props.user.postFailedData.length === 0;
    if (succeeded) {
      return (
        <Container maxWidth="md">
          <Box m={3}>
            <h2>{this.props.institution.institution.institution_name}</h2>
            <p>以下の利用者を登録しました。</p>
            <UsersList users={this.props.user.postSucceededData} invalidUsers={[]} withLink={false} />
            <Button color="primary" variant="contained" size="large" href={'/'}>
              施設一覧に戻る
            </Button>
          </Box>
        </Container>
      );
    } else {
      return (
        <Container maxWidth="md">
          <Box m={3}>
            <h2>{this.props.institution.institution.institution_name}</h2>
            <p>一部利用者の登録に失敗しました。システム管理者に問い合わせてください。</p>
            <UsersList
              users={this.props.user.postSucceededData}
              invalidUsers={this.props.user.postFailedData}
              withLink={false}
            />
            <Button color="primary" variant="contained" size="large" href={'/'}>
              施設一覧に戻る
            </Button>
          </Box>
        </Container>
      );
    }
  }
}
