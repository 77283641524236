import React, { Component } from 'react';
import { Box, Grid, Button, CircularProgress, Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { parse } from 'qs';
// eslint-disable-next-line no-unused-vars
import { reduxForm, InjectedFormProps } from 'redux-form';
import { institutionField, institutionPreviewField } from './InstitutionField';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import Error from '../containers/Error';

interface InstitutionUpdateFormProps extends InjectedFormProps<{}> {
  location: any;
  institutionActions: any;
  invalid: boolean;
  institution: any;
  auth: any;
  institutionUpdateForm: any;
}

interface InstitutionUpdateFormState {
  open: boolean;
}

class InstitutionUpdateForm extends Component<InstitutionUpdateFormProps, InstitutionUpdateFormState> {
  constructor(props: InstitutionUpdateFormProps) {
    super(props);

    this.state = {
      open: true,
    };
  }

  // TODO: componentWillMountはUNSAFEのため修正の必要あり
  // eslint-disable-next-line react/no-deprecated
  componentWillMount = () => {
    const query = parse(this.props.location.search.substr(1));
    this.props.institutionActions.get(query.id);
  };

  handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      open: false,
    });
  };

  attribute = (key: string, val: string) => {
    return new CognitoUserAttribute({
      Name: key,
      Value: val,
    });
  };

  // プレビュー On/Off.
  handlePreview = (on: boolean) => {
    if (on) {
      // 入力チェックして正しくないときは無視.
      if (this.props.invalid) {
        return;
      }
    }

    this.props.institutionActions.preview(on);
  };

  // 更新.
  handleSubmit = () => {
    // アカウントIDは深くにある.
    const institutionSub = this.props.institution.institution.institution_sub;
    // institution_id
    const institution_id = this.props.institution.institution.institution_id;
    const enabled = this.props.institutionUpdateForm.enabled;

    // updateDynamoDB と Enable/Disableの処理.
    this.props.institutionActions.updateDynamoDBAndEnable(
      institutionSub,
      {
        institution_name: this.props.institutionUpdateForm.institution_name,
        institution_name_kana: this.props.institutionUpdateForm.institution_name_kana,
        company_name: this.props.institutionUpdateForm.company_name,
        company_name_kana: this.props.institutionUpdateForm.company_name_kana,
        institution_phone: this.props.institutionUpdateForm.institution_phone,
        institution_email: this.props.institutionUpdateForm.institution_email,
        institution_care_id: this.props.institutionUpdateForm.institution_care_id,
        post_code: this.props.institutionUpdateForm.post_code,
        address: this.props.institutionUpdateForm.address,
        fax: this.props.institutionUpdateForm.fax,
        Administrator: this.props.institutionUpdateForm.Administrator,
        ContactPerson: this.props.institutionUpdateForm.ContactPerson,
        iPadCode: this.props.institutionUpdateForm.iPadCode,
        plan_type: this.props.institutionUpdateForm.plan_type,
        enabled: this.props.institutionUpdateForm.enabled,
      },
      institution_id,
      enabled,
    );
  };

  /**
   * API通信中かどうか（ボタン連打防止の為）.
   *
   * @returns
   * @memberof InstitutionUpdateForm
   */
  isAPICalling() {
    return (
      this.props.institution.DynamoDBUpdating || this.props.institution.Enabling || this.props.institution.Disabling
    );
  }

  render() {
    const institutionInfo = this.props.institution.institution;

    // ローディング.
    if (institutionInfo === null) {
      return (
        <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
          <Grid>
            <CircularProgress size={300} thickness={3} />
          </Grid>
        </Grid>
      );
    }

    if (!this.props.auth.isSuperUser) {
      return <p>このアカウントでは施設情報の更新はできません。</p>;
    }

    const wellStyles = {
      formStyle: { maxWidth: 800, margin: '0 auto 10px' },
      buttonStyle: { margin: 12, width: '100px', marginLeft: '340px' },
      buttonLabelStyle: { fontSize: '17px' },
      leftButtonStyle: { margin: 12, marginLeft: '300px' },
      resultButtonStyle: { margin: 12, marginLeft: '250px' },
    };

    const enabled = this.props.institutionUpdateForm.enabled;
    // プレビュー時.
    if (this.props.institution.Previewing) {
      return (
        <form style={wellStyles.formStyle}>
          {this.isAPICalling() ? institutionPreviewField(enabled, true) : institutionPreviewField(enabled, false)}
          <Box mt={4} px={30} display="flex" alignItems="center" justifyContent="space-evenly">
            <Button
              color="secondary"
              variant="contained"
              size="large"
              disabled={this.isAPICalling()}
              onClick={() => this.handlePreview(false)}
            >
              戻る
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={this.isAPICalling()}
              onClick={() => this.handleSubmit()}
            >
              更新する
            </Button>
          </Box>
          <br />
          <br />
          <br />
        </form>
      );
    } else {
      // 結果時.
      if (
        this.props.institution.DynamoDBUpdated === true &&
        // エラー時はfalse.
        this.props.institution.result === true
      ) {
        // 一覧画面.
        const returnUrl = '/institutions/list';

        return (
          <form style={wellStyles.formStyle}>
            {enabled ? institutionPreviewField(true, true) : institutionPreviewField(false, true)}
            <Box mt={3}>施設登録情報を更新しました。</Box>
            <Box mt={4} px={30} display="flex" alignItems="center" justifyContent="space-evenly">
              <Button
                color="secondary"
                variant="contained"
                size="large"
                onClick={() => {
                  window.print();
                }}
              >
                このページを印刷
              </Button>
              <Button color="primary" variant="contained" size="large" href={returnUrl}>
                施設一覧へ
              </Button>
            </Box>
            <br />
            <br />
            <br />
            <Snackbar
              message={'更新されました'}
              open={this.state.open}
              autoHideDuration={4000}
              action={
                <>
                  <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </>
              }
            />
          </form>
        );
      } else {
        // デフォルト.
        return (
          <form style={wellStyles.formStyle}>
            {enabled ? institutionField(true) : institutionField(false)}
            <Box mt={4} display="flex" alignItems="center" justifyContent="center">
              <Button
                color="primary"
                variant="contained"
                size="large"
                disabled={this.props.institution.DynamoDBUpdating || this.props.invalid || !this.props.dirty}
                onClick={() => this.handlePreview(true)}
              >
                更新確認
              </Button>
            </Box>
            <br />
            <br />
            <br />
            {// API実行されている.
            this.props.institution.DynamoDBUpdated === true && this.props.institution.err ? (
              <Error title={'施設情報更新時にエラーが発生しました'} message={this.props.institution.err.message} />
            ) : null}
          </form>
        );
      }
    }
  }
}

export default reduxForm<{}, any>({
  form: 'institutionUpdateForm',
  // 各プロパティはreducer
})(InstitutionUpdateForm);
