import React, { Component } from 'react';
import { Button } from '@material-ui/core';

const style: { [key: string]: React.CSSProperties } = {
  inputFileBtnHide: {
    opacity: 0,
    appearance: 'none',
    position: 'absolute',
  },
};

interface CSVLoaderProps extends React.Props<{}> {
  loadUsers: any;
  isDisable: boolean;
}

export default class CSVLoader extends Component<CSVLoaderProps, {}> {
  constructor(props: any) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      this.props.loadUsers(e.target.files[0]);
    }
    e.target.value = '';
  }

  render() {
    const buttonColor = this.props.isDisable ? 'default' : 'primary';
    return (
      <Button color={buttonColor} variant="contained" size="large">
        ファイルを選択する
        <input type="file" style={style.inputFileBtnHide} onChange={this.onChange} disabled={this.props.isDisable} />
      </Button>
    );
  }
}
