import React, { FC } from 'react';
import { Button, Box, Container, Grid, makeStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { FetchUserDataType } from '../../constants/User';

const useStyles = makeStyles({
  button: {
    textAlign: 'center',
    height: 80,
    maxWidht: 200,
    width: '70%',
    fontSize: 20,
    fontWeight: 'bold',
  },
});

interface SelectMonthProps {
  institution: any;
  user: FetchUserDataType;
  dateArr: string[];
  year: number;
  month: number;
  handleChangeForYear: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleChangeForMonth: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const SelectMonth: FC<SelectMonthProps> = ({
  institution,
  user,
  dateArr,
  year,
  month,
  handleChangeForYear,
  handleChangeForMonth,
}: SelectMonthProps) => {
  const classes = useStyles();
  const cnt: number = dateArr.length;

  return (
    <Container>
      <Grid container justify="space-between" alignItems="baseline">
        <Grid item xs={4}>
          <Box mt={4} fontSize={30} fontWeight="fontWeightBold">
            {institution.institution_name + '/' + user.user_name}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Link
            to={`/institutions/${institution.institution_sub}/check/${user.user_id}/create`}
            style={{ textDecoration: 'none' }}
          >
            <Button color="primary" variant="contained" size="large">
              新規登録
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Box my={4}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box fontSize={30} fontWeight="fontWeightBold">
              モフトレチェック
            </Box>
          </Grid>
          <Grid item container alignItems="baseline">
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-helper-label" />
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={year}
                onChange={handleChangeForYear}
              >
                {_.range(2017, dayjs().get('year') + 1).map((num, i) => {
                  return (
                    <MenuItem key={i} value={num}>
                      {num}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box mx={3} alignItems="baseline">
              年
            </Box>
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-helper-label" />
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={month}
                onChange={handleChangeForMonth}
              >
                {_.range(1, 13).map((num, i) => {
                  return (
                    <MenuItem key={i} value={num}>
                      {num}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box mx={3}>月 までの1年分を表示</Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container justify="flex-start" spacing={2}>
        {cnt === 0 ? (
          <p>データがありません</p>
        ) : (
          dateArr.map((date, i) => {
            const url = `/institutions/${institution.institution_sub}/check/${user.user_id}/${date}`;
            return (
              <Grid item xs={3} key={i}>
                <Box textAlign="center">
                  <Link to={url} style={{ textDecoration: 'none' }} data-testid={`month-button`}>
                    <Button variant="contained" className={classes.button}>
                      {date}
                    </Button>
                  </Link>
                </Box>
              </Grid>
            );
          })
        )}
      </Grid>
    </Container>
  );
};

export default SelectMonth;
