import * as Error from '../constants/Error';

export function alert(title: string, message: string) {
  return {
    type: Error.DISPLAY_ERROR,
    title: title,
    message: message,
  };
}

export function close() {
  return {
    type: Error.CLOSE_ERROR,
  };
}
