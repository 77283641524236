import React, { Component } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

export default class Loading extends Component {
  render() {
    return (
      <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
        <Grid>
          <CircularProgress size={300} thickness={3} />
        </Grid>
      </Grid>
    );
  }
}
