import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import * as InstitutionActions from '../../actions/Institution';
import * as UserActions from '../../actions/User/actions';
import * as CheckActions from '../../actions/Check/actions';
import { RootState } from '../../store/configureStore';
import MoffCheckSwap from '../../components/Check/MoffCheckEdit';
import Loading from '../../components/common/Loading';
import BackButton from '../../components/common/BackButton';
import { validateAndConvertNumber, getApiName, getRequestBody, getRequestBodySwap } from '../../utils/MoffCheck';

import { FetchUserDataType } from '../../constants/User';
import {
  CheckAPINamesList,
  CheckDataName,
  CheckRecord,
  CheckItemDisplay,
  RecordedAtNamesList,
} from '../../constants/Check';

const MoffCheckContainer: FC = () => {
  const dispatch = useDispatch();
  const institution = useSelector((state: RootState) => state.institution.institution);
  const users = useSelector((state: RootState) => state.user.listData);
  const check = useSelector((state: RootState) => state.check.data);
  const checkIsLoaded = useSelector((state: RootState) => state.check.loaded);
  const executing = useSelector((state: RootState) => state.check.executing);
  const swaped = useSelector((state: RootState) => state.check.swaped);
  const updated = useSelector((state: RootState) => state.check.updated);
  const { institutionId, userId, selectMonth, item, date } = useParams<{
    institutionId: string;
    userId: string;
    selectMonth: string;
    item: CheckDataName;
    date: string;
  }>();

  const extractCheckRecord = () => {
    let record: CheckRecord | undefined = {} as CheckRecord;
    if (check[item][selectMonth]) {
      if (RecordedAtNamesList.includes(item)) {
        record = (check[item][selectMonth] as any[]).find(record => record.recorded_at === date);
      } else {
        record = (check[item][selectMonth] as any[]).find(record => record.started_at === date);
      }
    }
    return record;
  };

  const [user, setUser] = useState(users.find((user: FetchUserDataType) => user.user_id === userId));
  const [selectedUniqueId, setSelectedUniqueId] = useState('');
  const isSuper = useSelector((state: RootState) => state.auth.isSuperUser);
  const record = extractCheckRecord();
  const prepareInitialValues = () => {
    const keys = Object.keys(CheckItemDisplay[item]);
    const initialValue: { [key: string]: string } = {};
    keys.forEach(key => {
      initialValue[key] = record ? String((record as any)[key]) : '';
    });
    return initialValue;
  };
  const joinDate = date.split(' ').join('T');
  const [datetime, setDatetime] = useState(joinDate);
  const [values, setValues] = useState<{ [key: string]: string }>(prepareInitialValues());

  useEffect(() => {
    if (!institution) {
      dispatch(InstitutionActions.get(institutionId));
    }
  }, [institution, institutionId, dispatch]);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(UserActions.getUsersWithUniqueId(institutionId));
    }
  }, [users, institutionId, dispatch]);

  useEffect(() => {
    setUser(users.find((user: FetchUserDataType) => user.user_id === userId));
  }, [users, userId]);

  useEffect(() => {
    if (!checkIsLoaded && user) {
      dispatch(CheckActions.resetCheckData());
      dispatch(CheckActions.setUniqueId(user.unique_id));
      dispatch(
        CheckActions.getCheckDataByYear(
          CheckAPINamesList,
          user.unique_id,
          Number(selectMonth.split('-')[0]),
          Number(selectMonth.split('-')[1]),
        ),
      );
    }
  }, [user, dispatch, selectMonth, checkIsLoaded]);

  const dateHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDatetime(event.target.value);
  };

  const valueHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedUniqueId(event.target.value as string);
  };

  const updateData = () => {
    const selectedUser = users.find((data: FetchUserDataType) => data.unique_id === selectedUniqueId);
    const apiName = getApiName(item);
    if (selectedUniqueId) {
      // 利用者変更
      const msg = `${selectedUser.user_name}のデータとして保存します。よろしいですか？`;
      if (window.confirm(msg)) {
        const body = getRequestBodySwap(item, user.unique_id, date);
        dispatch(CheckActions.swapCheckData(apiName, selectedUniqueId, body));
      }
    } else {
      // 値変更
      const convertValue = validateAndConvertNumber(values);
      const msg = '値を編集しますか？';
      if (convertValue && window.confirm(msg)) {
        const body = getRequestBody(item, convertValue, user.unique_id, datetime);
        const change_started_at = joinDate !== datetime;
        dispatch(CheckActions.putCheckData(apiName, user.unique_id, date, body, change_started_at));
      }
    }
  };

  if (!isSuper) {
    return <Redirect to="/" />;
  }

  if (swaped || updated) {
    alert('修正しました。');

    // 更新時は月選択画面へ遷移
    const url = swaped
      ? `/institutions/users/${institutionId}`
      : `/institutions/${institutionId}/select_month/${userId}`;
    return <Redirect to={url} />;
  }

  return user && institution && checkIsLoaded && record && !executing ? (
    <>
      <BackButton />
      <MoffCheckSwap
        institution={institution}
        user={user}
        users={users}
        selectedUniqueId={selectedUniqueId}
        selectMonth={selectMonth}
        valueObj={values}
        dateHandleChange={dateHandleChange}
        valueHandleChange={valueHandleChange}
        handleChange={handleChange}
        updateData={updateData}
        item={item}
        date={datetime}
      />
    </>
  ) : (
    <Loading />
  );
};

export default MoffCheckContainer;
