import * as Error from '../../constants/Error';

const initialState = {
  displayError: false,
  errorTitle: '',
  errorMessage: '',
};

/**
 * containerのmapStateToPropsにてマッピング.
 *
 * @export
 * @param {*} [state=initialState]
 * @param {*} action
 * @returns
 */
export default function error(state = initialState, action: any) {
  switch (action.type) {
    case Error.DISPLAY_ERROR:
      return Object.assign({}, state, {
        displayError: true,
        errorTitle: action.title,
        errorMessage: action.message,
      });
    case Error.CLOSE_ERROR:
      return Object.assign({}, state, {
        displayError: false,
        errorTitle: '',
        errorMessage: '',
      });
    default:
      return state;
  }
}
