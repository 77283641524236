import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import Header from '../containers/Header';
import Layout from './common/Layout';
import Footer from './common/Footer';

import Auth from '../containers/Auth';
import SignForm from '../containers/SignForm';

import Routes from './Routes';
import { history } from '../store/configureStore';

export interface StateProps {
  auth: any;
}

export interface DispatchProps {
  authActions: any;
}

export interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

export default class App extends Component<Props> {
  render() {
    return (
      <Layout>
        <ConnectedRouter history={history}>
          <Header />
          <Switch>
            <Route exact path="/" component={SignForm} />
          </Switch>
          <Auth>
            <Routes />
          </Auth>
          <Footer />
        </ConnectedRouter>
      </Layout>
    );
  }
}
