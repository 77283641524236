import { CheckActionTypes, CheckStateType } from '../../constants/Check';

export const initialState: CheckStateType = {
  unique_id: null,
  data: {
    tug: {},
    cs30: {},
    balance_right: {},
    balance_left: {},
    rom_101_right: {},
    rom_101_left: {},
    rom_602_right: {},
    rom_602_left: {},
    ss5: {},
    grip_right: {},
    grip_left: {},
    height: {},
    weight: {},
  },
  executing: false,
  idle: true,
  loaded: false,
  created: false,
  updated: false,
  deleted: false,
  swaped: false,
  error: null,
};

export default function check(state = initialState, action: any) {
  switch (action.type) {
    case CheckActionTypes.SET_UNIQUE_ID:
      return Object.assign({}, state, {
        unique_id: action.unique_id,
      });
    case CheckActionTypes.START_FETCH:
      return Object.assign({}, state, {
        idle: false,
        executing: true,
      });
    case CheckActionTypes.FINISH_FETCH:
      return Object.assign({}, state, {
        idle: true,
        executing: false,
        loaded: true,
        data: {
          tug: {
            ...state.data.tug,
            ...action.data.tug,
          },
          cs30: {
            ...state.data.cs30,
            ...action.data.cs30,
          },
          balance_right: {
            ...state.data.balance_right,
            ...action.data.balance_right,
          },
          balance_left: {
            ...state.data.balance_left,
            ...action.data.balance_left,
          },
          rom_101_right: {
            ...state.data.rom_101_right,
            ...action.data.rom_101_right,
          },
          rom_101_left: {
            ...state.data.rom_101_left,
            ...action.data.rom_101_left,
          },
          rom_602_right: {
            ...state.data.rom_602_right,
            ...action.data.rom_602_right,
          },
          rom_602_left: {
            ...state.data.rom_602_left,
            ...action.data.rom_602_left,
          },
          ss5: {
            ...state.data.ss5,
            ...action.data.ss5,
          },
          grip_right: {
            ...state.data.grip_right,
            ...action.data.grip_right,
          },
          grip_left: {
            ...state.data.grip_left,
            ...action.data.grip_left,
          },
          height: {
            ...state.data.height,
            ...action.data.height,
          },
          weight: {
            ...state.data.weight,
            ...action.data.weight,
          },
        },
      });
    case CheckActionTypes.START_CREATE:
      return Object.assign({}, state, {
        idle: false,
        executing: true,
      });
    case CheckActionTypes.FINISH_CREATE:
      return Object.assign({}, state, {
        idle: true,
        executing: false,
        created: true,
      });
    case CheckActionTypes.START_UPDATE:
      return Object.assign({}, state, {
        idle: false,
        executing: true,
      });
    case CheckActionTypes.FINISH_UPDATE:
      return Object.assign({}, state, {
        idle: true,
        executing: false,
        updated: true,
      });
    case CheckActionTypes.START_SWAP:
      return Object.assign({}, state, {
        idle: false,
        executing: true,
      });
    case CheckActionTypes.FINISH_SWAP:
      return Object.assign({}, state, {
        idle: true,
        executing: false,
        swaped: true,
      });
    case CheckActionTypes.START_DELETE:
      return Object.assign({}, state, {
        idle: false,
        executing: true,
      });
    case CheckActionTypes.FINISH_DELETE:
      return Object.assign({}, state, {
        idle: true,
        executing: false,
        deleted: true,
      });
    case CheckActionTypes.API_ERROR:
      return Object.assign({}, state, {
        idle: true,
        executing: false,
        error: action.error,
        loaded: false,
      });
    case CheckActionTypes.RESET_DATA:
      return initialState;
    default:
      return state;
  }
}
