import React, { Component, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import Loading from './Loading';

export interface StateProps {
  auth: any;
}

export interface DispatchProps {
  authActions: any;
}

export interface OwnProps {
  children: ReactNode;
}

type Props = StateProps & DispatchProps & OwnProps;

export default class Auth extends Component<Props> {
  componentDidMount = () => {
    this.props.authActions.getSession();
  };

  // componentDidUpdate = () => {
  //   this.props.authActions.getSession();
  // };

  render() {
    if (this.props.auth.isAuthenticated) {
      return this.props.children;
    } else if (this.props.auth.Loaded) {
      return <Redirect to="/" />;
    } else {
      return <Loading />;
    }
  }
}
