import { requestSignIn, requestGetSession, requestSignOut, checkSuperUser } from './api';
import * as Auth from '../../constants/Auth';
import { Dispatch } from 'redux';

export const signIn = (username: string, password: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Auth.PROCESS_SIGN_IN,
    });
    try {
      const session = await requestSignIn(username, password);
      const isSuperUser = checkSuperUser(session);
      dispatch({
        type: Auth.DONE_SIGN_IN,
        isAuthenticated: true,
        isSuperUser,
        err: null,
        result: true,
      });
    } catch (err) {
      dispatch({
        type: Auth.DONE_SIGN_IN,
        isAuthenticated: false,
        isSuperUser: false,
        err: err,
        result: false,
      });
    }
  };
};

export function getSession() {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: Auth.PROCESS_GET_SESSION,
    });
    try {
      const session = await requestGetSession();
      const isSuperUser = checkSuperUser(session);
      dispatch({
        type: Auth.DONE_GET_SESSION,
        isAuthenticated: true,
        isSuperUser,
        err: null,
        result: true,
      });
    } catch (err) {
      dispatch({
        type: Auth.DONE_GET_SESSION,
        isAuthenticated: false,
        isSuperUser: false,
        err: err,
        result: false,
      });
    }
  };
}

export function signOut() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Auth.PROCESS_SIGN_OUT,
    });
    try {
      requestSignOut();
      dispatch({
        type: Auth.DONE_SIGN_OUT,
        err: null,
        result: true,
      });
    } catch (err) {
      dispatch({
        type: Auth.DONE_SIGN_OUT,
        err: err,
        result: false,
      });
    }
  };
}
