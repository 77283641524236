import { CheckAPIName, CheckDataName, RecordedAtNamesList } from '../constants/Check';

export const validateAndConvertNumber = (valuesObj: { [key: string]: string }) => {
  const propertyArray = Object.entries(valuesObj);

  const isNotNumber = propertyArray.some(property => {
    if (property[1].match(/^[+,-]?([1-9]\d*|0)(\.\d+)?$/)) {
      return false;
    }
    return true;
  });
  if (isNotNumber) {
    alert('値が入力されていない、もしくは半角数字以外が入力されています。');
    return false;
  }

  const convertedValue: { [key: string]: number } = {};
  propertyArray.forEach(([key, value]) => {
    convertedValue[key] = Number(value);
  });

  return convertedValue;
};

export const getApiName = (item: CheckDataName): CheckAPIName => {
  let checkApiName: CheckAPIName;
  switch (item) {
    case 'balance_right':
    case 'balance_left':
      checkApiName = 'balance';
      break;
    case 'rom_101_right':
    case 'rom_101_left':
    case 'rom_602_right':
    case 'rom_602_left':
      checkApiName = 'rom';
      break;
    default:
      checkApiName = item;
      break;
  }
  return checkApiName;
};

export const getRequestBody = (
  item: CheckDataName,
  values: { [key: string]: string | number },
  uniqueId: string,
  startedAt: string,
) => {
  const onlyNumDatetime = startedAt.replace(/[^0-9]/g, '');
  const datetimeOmitT = startedAt.replace('T', ' ');

  let body: { [key: string]: string | number } = {};
  switch (item) {
    case 'tug':
      body = {
        unique_id: uniqueId,
        data_id: `${uniqueId}+walking_tug+${onlyNumDatetime}`,
        started_at: datetimeOmitT,
        ...values,
      };
      break;
    case 'cs30':
      body = {
        unique_id: uniqueId,
        data_id: `${uniqueId}+cs30+${onlyNumDatetime}`,
        started_at: datetimeOmitT,
        ...values,
      };
      break;
    case 'balance_right':
      body = {
        unique_id: uniqueId,
        data_id: `${uniqueId}_right_leg+${onlyNumDatetime}`,
        measurement_type: 'right_leg',
        started_at: datetimeOmitT,
        ...values,
      };
      break;
    case 'balance_left':
      body = {
        unique_id: uniqueId,
        data_id: `${uniqueId}_left_leg+${onlyNumDatetime}`,
        measurement_type: 'left_leg',
        started_at: datetimeOmitT,
        ...values,
      };
      break;
    case 'rom_101_right':
      body = {
        unique_id: uniqueId,
        started_at: datetimeOmitT,
        item_id: '101',
        position: 'Right',
        ...values,
      };
      break;
    case 'rom_101_left':
      body = {
        unique_id: uniqueId,
        started_at: datetimeOmitT,
        item_id: '101',
        position: 'Left',
        ...values,
      };
      break;
    case 'rom_602_right':
      body = {
        unique_id: uniqueId,
        started_at: datetimeOmitT,
        item_id: '602',
        position: 'Right',
        ...values,
      };
      break;
    case 'rom_602_left':
      body = {
        unique_id: uniqueId,
        started_at: datetimeOmitT,
        item_id: '602',
        position: 'Left',
        ...values,
      };
      break;
    case 'ss5':
      body = {
        unique_id: uniqueId,
        started_at: datetimeOmitT,
        data_id: `${uniqueId}+walking_tug+${onlyNumDatetime}`,
        ...values,
      };
      break;
    case 'grip_right':
    case 'grip_left':
    case 'height':
    case 'weight':
      body = {
        unique_id: uniqueId,
        recorded_at: datetimeOmitT,
        ...values,
      };
      break;
    default:
      throw Error(`${item} is not exist`);
  }
  return body;
};

export const getRequestBodySwap = (item: CheckDataName, uniqueId: string, startedAt: string) => {
  let body: { [key: string]: string | number } = {};
  let physicalId = 0;
  switch (item) {
    case 'grip_right':
      physicalId = 0;
      break;
    case 'grip_left':
      physicalId = 1;
      break;
    case 'height':
      physicalId = 2;
      break;
    case 'weight':
      physicalId = 3;
      break;
    default:
      break;
  }

  if (RecordedAtNamesList.includes(item)) {
    body = {
      unique_id: uniqueId,
      physical_id: physicalId,
      recorded_at: startedAt,
    };
  } else {
    body = {
      unique_id: uniqueId,
      started_at: startedAt,
    };
  }
  return body;
};
