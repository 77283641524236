import { Button } from '@material-ui/core';
import React, { Component } from 'react';

interface PostUsersButtonProps extends React.Props<{}> {
  users: any;
  createUsers: any;
  institutionSub: string;
  isDisable: boolean;
  history: any;
}

export default class PostUsersButton extends Component<PostUsersButtonProps, {}> {
  constructor(props: any) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.createUsers(this.props.institutionSub, this.props.users);
    this.props.history.push(`/institutions/users/${this.props.institutionSub}/created`);
  }

  render() {
    const buttonColor = this.props.isDisable ? 'default' : 'primary';
    return (
      <Button
        color={buttonColor}
        variant="contained"
        size="large"
        onClick={this.onClick}
        disabled={this.props.isDisable}
      >
        登録
      </Button>
    );
  }
}
