import { CheckAdminClient } from './api';
import {
  BalanceResponse,
  GripResponse,
  CheckActionTypes,
  CheckAPIName,
  CheckAPINamesList,
  ROMResponse,
  CheckApiFetchResponse,
  TUGResponse,
  CS30Response,
  SS5Response,
  RecordedAtNamesList,
} from '../../constants/Check';
import { Dispatch } from 'redux';

const separateBalance = (data: BalanceResponse[]) => {
  return {
    balance_right: data.filter(ele => {
      return ele.measurement_type === 'right_leg';
    }),
    balance_left: data.filter(ele => {
      return ele.measurement_type === 'left_leg';
    }),
  };
};

const separateROM = (data: ROMResponse[]) => {
  return {
    rom_101_right: data.filter(ele => {
      return ele.item_id === '101' && ele.position === 'Right';
    }),
    rom_101_left: data.filter(ele => {
      return ele.item_id === '101' && ele.position === 'Left';
    }),
    rom_602_right: data.filter(ele => {
      return ele.item_id === '602' && ele.position === 'Right';
    }),
    rom_602_left: data.filter(ele => {
      return ele.item_id === '602' && ele.position === 'Left';
    }),
  };
};

const checkAPIResponseModifer = (res: { [s: string]: any }[]) => {
  const rawData = res.reduce((data, ele) => {
    return { ...data, ...ele };
  }) as CheckApiFetchResponse;
  CheckAPINamesList.forEach(name => {
    if (!rawData[name]) {
      rawData[name] = [];
    }
  });
  const { balance, rom, ...rest } = rawData;
  return {
    ...separateBalance(balance),
    ...separateROM(rom),
    ...rest,
  };
};

export const getCheckDataByMonth = (names: CheckAPIName[], unique_id: string, year: number, month: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CheckActionTypes.START_FETCH });
    try {
      const api = await CheckAdminClient.ClientFactory();
      const res = await Promise.all(
        names.map(name => {
          return api.getDataByMonth(name, unique_id, year, month);
        }),
      );

      const separatedData = checkAPIResponseModifer(res);

      const yearMonth = `${year}-${('0' + month).slice(-2)}`;
      const data = Object.fromEntries(
        Object.entries(separatedData).map(([key, value]) => {
          return [key, { [yearMonth]: value }];
        }),
      );
      dispatch({
        type: CheckActionTypes.FINISH_FETCH,
        data,
      });
    } catch (error) {
      dispatch({
        type: CheckActionTypes.API_ERROR,
        error,
      });
    }
  };
};

export const getCheckDataByYear = (names: CheckAPIName[], unique_id: string, year: number, month: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CheckActionTypes.START_FETCH });
    try {
      const api = await CheckAdminClient.ClientFactory();
      const res = await Promise.all(
        names.map(name => {
          return api.getDataByYear(name, unique_id, year, month);
        }),
      );
      const separatedData = checkAPIResponseModifer(res);
      const yearMonthList = ((year: number, month: number) => {
        const list = [];
        for (let i = 0; i < 12; i++) {
          const date = new Date(year, month - i - 1, 1);
          list.push(`${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}`);
        }
        return list;
      })(year, month);

      const data = Object.fromEntries(
        Object.entries(separatedData).map(([key, value]) => {
          const yearMonthData = Object.fromEntries(
            yearMonthList.map(yearMonth => {
              if (RecordedAtNamesList.includes(key)) {
                return [
                  yearMonth,
                  (value as GripResponse[]).filter(record => record.recorded_at.startsWith(yearMonth)),
                ];
              } else {
                return [
                  yearMonth,
                  (value as (
                    | TUGResponse
                    | CS30Response
                    | BalanceResponse
                    | SS5Response
                    | ROMResponse
                  )[]).filter(record => record.started_at.startsWith(yearMonth)),
                ];
              }
            }),
          );
          return [key, yearMonthData];
        }),
      );
      dispatch({
        type: CheckActionTypes.FINISH_FETCH,
        data,
      });
    } catch (error) {
      dispatch({
        type: CheckActionTypes.API_ERROR,
        error,
      });
    }
  };
};

export const postCheckData = (name: CheckAPIName, unique_id: string, data: { [s: string]: any }) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CheckActionTypes.START_CREATE });
    try {
      const api = await CheckAdminClient.ClientFactory();
      await api.postData(name, unique_id, data);
      dispatch({
        type: CheckActionTypes.FINISH_CREATE,
      });
    } catch (error) {
      dispatch({
        type: CheckActionTypes.API_ERROR,
        error,
      });
    }
  };
};

export const putCheckData = (
  name: CheckAPIName,
  unique_id: string,
  date: string,
  data: { [s: string]: any },
  change_started_at = false,
) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CheckActionTypes.START_UPDATE });
    try {
      const api = await CheckAdminClient.ClientFactory();
      await api.putData(name, unique_id, date, data, change_started_at);
      dispatch({
        type: CheckActionTypes.FINISH_UPDATE,
      });
    } catch (error) {
      dispatch({
        type: CheckActionTypes.API_ERROR,
        error,
      });
    }
  };
};

export const swapCheckData = (name: CheckAPIName, unique_id: string, data: { [s: string]: any }) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CheckActionTypes.START_SWAP });
    try {
      const api = await CheckAdminClient.ClientFactory();
      await api.swapData(name, unique_id, data);
      dispatch({
        type: CheckActionTypes.FINISH_SWAP,
      });
    } catch (error) {
      dispatch({
        type: CheckActionTypes.API_ERROR,
        error,
      });
    }
  };
};

export const deleteCheckData = (name: CheckAPIName, unique_id: string, date: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: CheckActionTypes.START_DELETE });
    try {
      const api = await CheckAdminClient.ClientFactory();
      await api.deleteData(name, unique_id, date);
      dispatch({
        type: CheckActionTypes.FINISH_DELETE,
      });
    } catch (error) {
      dispatch({
        type: CheckActionTypes.API_ERROR,
        error,
      });
    }
  };
};

export const setUniqueId = (unique_id: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CheckActionTypes.SET_UNIQUE_ID,
      unique_id: unique_id,
    });
  };
};

export const resetCheckData = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CheckActionTypes.RESET_DATA,
    });
  };
};
