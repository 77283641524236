import * as Institution from '../../constants/Institution';

export const initialState = {
  listData: {},
  institution: null,
  result: false,
  Loading: false,
  Loaded: false,
  Creating: false,
  Created: false,
  SigningUp: false,
  SignedUp: false,
  Confirming: false,
  Confirmed: false,
  Updating: false,
  Updated: false,
  DynamoDBUpdating: false,
  DynamoDBUpdated: false,
  CognitoUpdating: false,
  CognitoUpdated: false,
};

export default function institution(state = initialState, action: any) {
  switch (action.type) {
    case Institution.LOADING:
      return Object.assign({}, state, {
        Loading: true,
        Loaded: false,
      });
    case Institution.LOADED:
      return Object.assign({}, state, {
        Loading: false,
        Loaded: true,
        institution: action.data,
        result: action.result,
      });
    case Institution.LIST_LOADING:
      return Object.assign({}, state, {
        Loading: true,
        Loaded: false,
      });
    case Institution.LIST_LOADED:
      return Object.assign({}, state, {
        Loading: false,
        Loaded: true,
        listData: action.data,
        result: action.result,
      });
    case Institution.SIGNING_UP:
      return Object.assign({}, state, {
        Creating: true,
        Created: false,
        SigningUp: true,
        SignedUp: false,
      });
    case Institution.SIGNED_UP:
      return Object.assign({}, state, {
        SigningUp: false,
        SignedUp: true,
        err: action.err,
        Previewing: false,
      });
    case Institution.CONFIRMING:
      return Object.assign({}, state, {
        SignedUp: false,
        Confirming: true,
        Confirmed: false,
      });
    case Institution.CONFIRMED:
      return Object.assign({}, state, {
        Creating: false,
        Created: true,
        Confirming: false,
        Confirmed: true,
        result: action.result,
        err: action.err,
        Previewing: false,
      });
    case Institution.DYNAMODB_UPDATING:
      return Object.assign({}, state, {
        DynamoDBUpdating: true,
        DynamoDBUpdated: false,
      });
    case Institution.DYNAMODB_UPDATED:
      return Object.assign({}, state, {
        DynamoDBUpdating: false,
        DynamoDBUpdated: true,
        result: action.result,
        err: action.err,
        Previewing: false,
        Enabing: false,
        Disabling: false,
      });
    // TODO: 単体で使わないのでENABLING/DISABLING系は不要になると思う.
    case Institution.ENABLING:
      return Object.assign({}, state, {
        Enabling: true,
        Enabled: false,
      });
    case Institution.ENABLED:
      return Object.assign({}, state, {
        Updating: false,
        Updated: true,
        Enabling: false,
        Enabled: true,
        result: action.result,
        err: action.err,
        Previewing: false,
      });
    case Institution.DISABLING:
      return Object.assign({}, state, {
        Disabling: true,
        Disabed: false,
      });
    case Institution.DISABLED:
      return Object.assign({}, state, {
        Updating: false,
        Updated: true,
        Disabling: false,
        Disabed: true,
        result: action.result,
        err: action.err,
        Previewing: false,
      });
    // TODO: おそらく"COGNITO_"は使われなくなる.
    case Institution.COGNITO_UPDATING:
      return Object.assign({}, state, {
        DynamoDBUpdated: false,
        CognitoUpdating: true,
        CognitoUpdated: false,
      });
    case Institution.COGNITO_UPDATED:
      return Object.assign({}, state, {
        Updating: false,
        Updated: true,
        CognitoUpdating: false,
        CognitoUpdated: true,
        result: action.result,
      });
    case Institution.PREVIEW_ON:
      return Object.assign({}, state, {
        // 一旦リセット.
        Updating: false,
        Updated: false,
        Previewing: true,
        err: '',
      });
    case Institution.PREVIEW_OFF:
      return Object.assign({}, state, {
        Previewing: false,
      });
    default:
      return state;
  }
}
