import React, { Component } from 'react';
import logo from './logo.svg';
import './NotFound.css';

class NotFound extends Component {
  render() {
    return (
      <div>
        <div className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2>NotFound</h2>
        </div>
        <p className="App-intro">path not found</p>
      </div>
    );
  }
}

export default NotFound;
