import React, { Component } from 'react';
import { UserCareLevelMap, UserGenderMap, UserVisitDayOrderMap } from '../../constants/User';
import {
  createStyles,
  Popover,
  StyleRules,
  TableCell,
  TableRow,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    valid: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    invalid: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.warning.main,
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.warning.light,
      },
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
      fontSize: 12,
    },
    body: {
      fontSize: 14,
    },
  });

interface UserTableRowProps extends WithStyles<typeof styles> {
  user: any;
  isValid: boolean;
  withLink: boolean;
  institutionSub?: string;
}

interface UserTableRowState {
  anchorEl: any;
}

class UserTableRow extends Component<UserTableRowProps, UserTableRowState> {
  constructor(props: any) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
  }

  handlePopoverOpen(e: React.MouseEvent) {
    this.setState({
      anchorEl: e.currentTarget,
    });
  }

  handlePopoverClose() {
    this.setState({
      anchorEl: null,
    });
  }

  render() {
    const valid = this.props.isValid;
    const hasErrors = Object.prototype.hasOwnProperty.call(this.props.user, 'errors');
    const user = this.props.user;
    const open = Boolean(this.state.anchorEl) && !valid && hasErrors;
    const institution_sub = this.props.institutionSub;
    const selectMonthUrl = `/institutions/${institution_sub}/select_month/${user.user_id}`;
    return (
      <TableRow
        className={valid ? this.props.classes.valid : this.props.classes.invalid}
        aria-owns={open ? 'mouse-over' : undefined}
        aria-haspopup="true"
        onMouseEnter={this.handlePopoverOpen}
        onMouseLeave={this.handlePopoverClose}
      >
        {this.props.withLink ? (
          <TableCell align="left" className={this.props.classes.body}>
            <Link to={selectMonthUrl} style={{ textDecoration: 'none' }}>
              {user.user_name}
            </Link>
          </TableCell>
        ) : (
          <TableCell align="left" className={this.props.classes.body}>
            {user.user_name}
          </TableCell>
        )}
        <TableCell align="left" className={this.props.classes.body}>
          {user.user_name_kana}
        </TableCell>
        <TableCell align="left" className={this.props.classes.body}>
          {UserGenderMap[user.user_gender]}
        </TableCell>
        <TableCell align="left" className={this.props.classes.body}>
          {user.user_birth_year}/{user.user_birth_month}/{user.user_birth_day}
        </TableCell>
        <TableCell align="left" className={this.props.classes.body}>
          {user.user_care_id}
        </TableCell>
        <TableCell align="left" className={this.props.classes.body}>
          {UserCareLevelMap[user.user_care_level]}
        </TableCell>
        <TableCell align="left" className={this.props.classes.body}>
          {user.user_visit_day
            .map((flag: number, index: number) => {
              if (flag === 1) {
                return UserVisitDayOrderMap[index];
              } else {
                return '';
              }
            })
            .filter((day: string) => day !== '')
            .join('・')}
        </TableCell>
        <TableCell align="left" className={this.props.classes.body}>
          {user.user_insurer_no}
        </TableCell>
        <Popover
          id="mouse-over"
          className={this.props.classes.popover}
          classes={{ paper: this.props.classes.paper }}
          open={open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          {open
            ? Object.values(user.errors).map((message, index) => {
                return (
                  <p key={index} style={{ margin: 0 }}>
                    ・{message}
                  </p>
                );
              })
            : ''}
        </Popover>
      </TableRow>
    );
  }
}

export default withStyles(styles)(UserTableRow);
