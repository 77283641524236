import * as api from './api';
import { loadUsers } from './csv';
import { UserActionTypes } from '../../constants/User';
import { Dispatch } from 'redux';

// api actions
export const getUsers = (institutionSub: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UserActionTypes.START_FETCH,
    });

    try {
      const listData = await api.getUsersByInstitutionSub(institutionSub);
      dispatch({
        type: UserActionTypes.END_FETCH,
        listData,
      });
    } catch (error) {
      dispatch({
        type: UserActionTypes.API_ERROR,
        error,
      });
    }
  };
};

export const getUsersWithUniqueId = (institutionSub: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UserActionTypes.START_FETCH,
    });

    try {
      const Users = await api.getUsersByInstitutionSub(institutionSub);
      const AppUsers = await api.getAppUsers(institutionSub);
      const listData: any = Users.map((user: any): object => {
        const appUser = AppUsers.find((data: any) => {
          return data.app_user_id === user.user_id;
        });
        return { ...user, unique_id: appUser ? appUser.unique_id : null };
      });
      dispatch({
        type: UserActionTypes.END_FETCH,
        listData,
      });
    } catch (error) {
      dispatch({
        type: UserActionTypes.API_ERROR,
        error,
      });
    }
  };
};

export const createUsers = (institutionSub: string, userList: { [s: string]: any }[]) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UserActionTypes.START_CREATE,
    });

    // check existing users again before post
    const existingUsers = await api.getUsersByInstitutionSub(institutionSub);
    if (existingUsers.length > 0) {
      dispatch({
        type: UserActionTypes.API_ERROR,
        error: 'ALREADY_REGISTERED',
      });
      return;
    }

    // post all users at once
    try {
      const { postSucceededData, postFailedData } = await api.createUsersByInstitutionSub(institutionSub, userList);
      dispatch({
        type: UserActionTypes.END_CREATE,
        postSucceededData,
        postFailedData,
      });
    } catch (error) {
      dispatch({
        type: UserActionTypes.API_ERROR,
        error,
      });
    }
  };
};

// csv load actions
export const resetCSVLoading = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UserActionTypes.START_CSV_LOAD,
    });
  };
};

export const loadUsersFromCSV = (file: File) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UserActionTypes.START_CSV_LOAD,
    });

    try {
      const { prePostData, invalidPrePostData } = await loadUsers(file);
      dispatch({
        type: UserActionTypes.END_CSV_LOAD,
        prePostData,
        invalidPrePostData,
      });
    } catch (error) {
      dispatch({
        type: UserActionTypes.CSV_LOAD_ERROR,
        error,
      });
    }
  };
};
