const initialState = {
  app: null,
  sidebar: false,
};

export default function signin(state = initialState, action: any) {
  switch (action.type) {
    default:
      return state;
  }
}
