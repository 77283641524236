export enum CheckActionTypes {
  SET_UNIQUE_ID = 'CHECK_SET_UNIQUE_ID',
  START_FETCH = 'CHECK_START_FETCH',
  FINISH_FETCH = 'CHECK_FINISH_FETCH',
  START_CREATE = 'CHECK_START_CREATE',
  FINISH_CREATE = 'CHECK_FINISH_CREATE',
  START_UPDATE = 'CHECK_START_UPDATE',
  FINISH_UPDATE = 'CHECK_FINISH_UPDATE',
  START_SWAP = 'CHECK_START_SWAP',
  FINISH_SWAP = 'CHECK_FINISH_SWAP',
  START_DELETE = 'CHECK_START_DELETE',
  FINISH_DELETE = 'CHECK_FINISH_DELETE',
  RESET_DATA = 'CHECK_RESET_DATA',
  API_ERROR = 'CHECK_API_ERROR',
}

export enum CheckNames {
  'tug' = 'TUG',
  'cs30' = 'CS-30',
  'balance_right' = '開眼片足立ち（右）',
  'balance_left' = '開眼片足立ち（左）',
  'rom_101_right' = 'ROM：肩屈曲（右）',
  'rom_101_left' = 'ROM：肩屈曲（左）',
  'rom_602_right' = 'ROM：膝伸展（右）',
  'rom_602_left' = 'ROM：膝伸展（左）',
  'ss5' = 'SS-5',
  'grip_right' = '握力（右）',
  'grip_left' = '握力（左）',
  'height' = '身長',
  'weight' = '体重',
}

export type CheckAPIName = keyof CheckApiFetchResponse;

export const CheckAPINamesList: CheckAPIName[] = [
  'tug',
  'cs30',
  'balance',
  'rom',
  'ss5',
  'grip_right',
  'grip_left',
  'height',
  'weight',
];

export const RecordedAtNamesList = ['grip_right', 'grip_left', 'height', 'weight'];

export type CheckDataName = keyof CheckData;

export const CheckDataNamesList: CheckDataName[] = [
  'tug',
  'cs30',
  'balance_right',
  'balance_left',
  'rom_101_right',
  'rom_101_left',
  'rom_602_right',
  'rom_602_left',
  'ss5',
  'grip_right',
  'grip_left',
  'height',
  'weight',
];

export type TUGResponse = {
  unique_id: string;
  data_id: string;
  time: number;
  stand_up: number;
  out: number;
  out_turn: number;
  in: number;
  in_turn: number;
  sit: number;
  started_at: string;
  created_at: string;
  updated_at: string;
};

export type CS30Response = {
  unique_id: string;
  data_id: string;
  count: number;
  started_at: string;
  created_at: string;
  updated_at: string;
};

export type BalanceResponse = {
  unique_id: string;
  data_id: string;
  time: number;
  measurement_type: 'right_leg' | 'left_leg';
  started_at: string;
  created_at: string;
  updated_at: string;
};

export type ROMResponse = {
  unique_id: string;
  data_id: string;
  item_id: '101' | '602';
  item_id_started_at: string;
  item_position_id: string;
  position: 'Left' | 'Right';
  angle: number;
  init_angle: number;
  started_at: string;
  created_at: string;
  updated_at: string;
};

export type SS5Response = {
  unique_id: string;
  data_id: string;
  count: number;
  time: number;
  started_at: string;
  created_at: string;
  updated_at: string;
};

export type GripResponse = {
  unique_id: string;
  physical_id: 0 | 1 | 2 | 3;
  physical_id_recorded_at: string;
  value: number;
  recorded_at: string;
  created_at: string;
  updated_at: string;
};

export type CheckRecord = TUGResponse | CS30Response | BalanceResponse | ROMResponse | SS5Response | GripResponse;

export type CheckApiFetchResponse = {
  tug: TUGResponse[];
  cs30: CS30Response[];
  balance: BalanceResponse[];
  rom: ROMResponse[];
  ss5: SS5Response[];
  grip_right: GripResponse[];
  grip_left: GripResponse[];
  height: GripResponse[];
  weight: GripResponse[];
};

export type CheckData = {
  tug: { [s: string]: TUGResponse[] };
  cs30: { [s: string]: CS30Response[] };
  balance_right: { [s: string]: BalanceResponse[] };
  balance_left: { [s: string]: BalanceResponse[] };
  rom_101_right: { [s: string]: ROMResponse[] };
  rom_101_left: { [s: string]: ROMResponse[] };
  rom_602_right: { [s: string]: ROMResponse[] };
  rom_602_left: { [s: string]: ROMResponse[] };
  ss5: { [s: string]: SS5Response[] };
  grip_right: { [s: string]: GripResponse[] };
  grip_left: { [s: string]: GripResponse[] };
  height: { [s: string]: GripResponse[] };
  weight: { [s: string]: GripResponse[] };
};

export type CheckStateType = {
  data: CheckData;
  unique_id: string | null;
  idle: boolean;
  executing: boolean;
  loaded: boolean;
  created: boolean;
  updated: boolean;
  deleted: boolean;
  swaped: boolean;
  error: Error | null;
};

export const CheckItemProperties = {
  tug: {
    value: 'time',
    date: 'started_at',
    unit: '秒',
  },
  cs30: {
    value: 'count',
    date: 'started_at',
    unit: '回',
  },
  balance_right: {
    value: 'time',
    date: 'started_at',
    unit: '秒',
  },
  balance_left: {
    value: 'time',
    date: 'started_at',
    unit: '秒',
  },
  ss5: {
    value: 'time',
    date: 'started_at',
    unit: '秒',
  },
  rom_101_right: {
    value: 'angle',
    date: 'started_at',
    unit: '度',
  },
  rom_101_left: {
    value: 'angle',
    date: 'started_at',
    unit: '度',
  },
  rom_602_right: {
    value: 'angle',
    date: 'started_at',
    unit: '度',
  },
  rom_602_left: {
    value: 'angle',
    date: 'started_at',
    unit: '度',
  },
  grip_right: {
    value: 'value',
    date: 'recorded_at',
    unit: 'kg',
  },
  grip_left: {
    value: 'value',
    date: 'recorded_at',
    unit: 'kg',
  },
  height: {
    value: 'value',
    date: 'recorded_at',
    unit: 'cm',
  },
  weight: {
    value: 'value',
    date: 'recorded_at',
    unit: 'kg',
  },
};

export const CheckItemDisplay = {
  tug: {
    time: {
      name: '時間',
      unit: '秒',
    },
    stand_up: {
      name: '起立',
      unit: '秒',
    },
    out: {
      name: '往路',
      unit: '秒',
    },
    out_turn: {
      name: '往路ターン',
      unit: '秒',
    },
    in: {
      name: '復路',
      unit: '秒',
    },
    in_turn: {
      name: '復路ターン',
      unit: '秒',
    },
    sit: {
      name: '着席',
      unit: '秒',
    },
  },
  cs30: {
    count: {
      name: '回数',
      unit: '回',
    },
  },
  balance_right: {
    time: {
      name: '時間',
      unit: '秒',
    },
  },
  balance_left: {
    time: {
      name: '時間',
      unit: '秒',
    },
  },
  ss5: {
    time: {
      name: '時間',
      unit: '秒',
    },
    count: {
      name: '回数',
      unit: '回',
    },
  },
  rom_101_right: {
    angle: {
      name: '角度',
      unit: '度',
    },
    init_angle: {
      name: '初期角度',
      unit: '度',
    },
  },
  rom_101_left: {
    angle: {
      name: '角度',
      unit: '度',
    },
    init_angle: {
      name: '初期角度',
      unit: '度',
    },
  },
  rom_602_right: {
    angle: {
      name: '角度',
      unit: '度',
    },
    init_angle: {
      name: '初期角度',
      unit: '度',
    },
  },
  rom_602_left: {
    angle: {
      name: '角度',
      unit: '度',
    },
    init_angle: {
      name: '初期角度',
      unit: '度',
    },
  },
  grip_right: {
    value: {
      name: '値',
      unit: 'kg',
    },
  },
  grip_left: {
    value: {
      name: '値',
      unit: 'kg',
    },
  },
  height: {
    value: {
      name: '値',
      unit: 'cm',
    },
  },
  weight: {
    value: {
      name: '値',
      unit: 'kg',
    },
  },
};
