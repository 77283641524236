import React, { Component } from 'react';
import { Box, Grid, Button, Snackbar, CircularProgress, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// eslint-disable-next-line no-unused-vars
import { reduxForm, InjectedFormProps } from 'redux-form';
import { institutionNewField, institutionNewPreviewField } from './InstitutionField';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import Error from '../containers/Error';

interface InstitutionCreateFormProps extends InjectedFormProps<{}> {
  invalid: boolean;
  institutionActions: any;
  institutionCreateForm: any;
  institution: any;
  auth: any;
  handleSubmit: any;
}

interface InstitutionCreateFormState {
  open: boolean;
}

class InstitutionCreateForm extends Component<InstitutionCreateFormProps, InstitutionCreateFormState> {
  constructor(props: InstitutionCreateFormProps) {
    super(props);

    this.state = {
      open: true,
    };
  }

  handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      open: false,
    });
  };

  attribute = (key: string, val: string) => {
    return new CognitoUserAttribute({
      Name: key,
      Value: val,
    });
  };

  // プレビュー On/Off.
  handlePreview = (on: boolean) => {
    if (on) {
      // 入力チェックして正しくないときは無視.
      if (this.props.invalid) {
        return;
      }
    }

    this.props.institutionActions.preview(on);
  };

  handleSubmit = () => {
    this.props.institutionActions.signUpAndConfirm(
      // アカウントIDとpassword.
      this.props.institutionCreateForm.institution_id,
      this.props.institutionCreateForm.password,
      // 配列を渡すことに注意.
      // 必須ではないところでも空文字を入れないといけない.
      [
        { Name: 'email', Value: this.props.institutionCreateForm.institution_email },
        { Name: 'custom:InstitutionName', Value: this.props.institutionCreateForm.institution_name },
        { Name: 'custom:InstitutionNameKana', Value: this.props.institutionCreateForm.institution_name_kana },
        { Name: 'custom:CompanyName', Value: this.props.institutionCreateForm.company_name },
        { Name: 'custom:CompanyNameKana', Value: this.props.institutionCreateForm.company_name_kana },
        // サーバ側でinstitutionCareIdをケアする必要がある(?)ので、ここでは送らない.
        // { Name: 'custom:InstitutionCareId', Value: this.props.institutionCreateForm.institution_care_id },
        { Name: 'custom:InstitutionNumber', Value: this.props.institutionCreateForm.institution_id },
        { Name: 'custom:ApplicationDate', Value: this.props.institutionCreateForm.application_year_month },
        { Name: 'custom:Tel', Value: this.props.institutionCreateForm.institution_phone || '' },
        { Name: 'custom:PostCode', Value: this.props.institutionCreateForm.post_code || '' },
        { Name: 'custom:Address', Value: this.props.institutionCreateForm.address || '' },
        { Name: 'custom:Fax', Value: this.props.institutionCreateForm.fax || '' },
        { Name: 'custom:Administrator', Value: this.props.institutionCreateForm.Administrator || '' },
        { Name: 'custom:ContactPerson', Value: this.props.institutionCreateForm.ContactPerson || '' },
        { Name: 'custom:iPadCode', Value: this.props.institutionCreateForm.iPadCode || '' },
        { Name: 'custom:PlanType', Value: this.props.institutionCreateForm.plan_type || '' },
      ],
    );
  };

  /**
   * API通信中かどうか（ボタン連打防止の為）.
   *
   * @returns
   * @memberof InstitutionUpdateForm
   */
  isAPICalling() {
    return this.props.institution.SigningUp || this.props.institution.Confirming;
  }

  render() {
    // ローディング.
    if (this.props.institution.Confirming) {
      return (
        <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
          <Grid>
            <CircularProgress size={300} thickness={3} />
          </Grid>
        </Grid>
      );
    }

    if (!this.props.auth.isSuperUser) {
      return <p>このアカウントでは施設の作成はできません。</p>;
    }

    const wellStyles = {
      formStyle: { maxWidth: 800, margin: '0 auto 10px' },
      buttonStyle: { margin: 12, width: '100px', marginLeft: '340px' },
      buttonLabelStyle: { fontSize: '17px' },
      leftButtonStyle: { margin: 12, marginLeft: '300px' },
      resultButtonStyle: { margin: 12, marginLeft: '250px' },
      messageStyle: { width: '800px' },
    };

    // プレビュー時.
    if (this.props.institution.Previewing) {
      return (
        <form style={wellStyles.formStyle}>
          {institutionNewPreviewField}
          <Box mt={4} px={30} display="flex" alignItems="center" justifyContent="space-evenly">
            <Button
              color="secondary"
              variant="contained"
              size="large"
              disabled={this.isAPICalling()}
              onClick={() => this.handlePreview(false)}
            >
              戻る
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={this.isAPICalling()}
              onClick={() => this.handleSubmit()}
            >
              施設を登録する
            </Button>
          </Box>
          <br />
          <br />
          <br />
        </form>
      );
    } else {
      // 結果時.
      if (
        this.props.institution.Confirmed === true &&
        // エラー時はfalse.
        this.props.institution.result === true
      ) {
        return (
          <form style={wellStyles.formStyle}>
            {institutionNewPreviewField}
            <div>
              <p style={wellStyles.messageStyle}>お客様に上記アカウントとパスワードをお伝えください。</p>
              <br />
              <p>【重要】</p>
              <p style={wellStyles.messageStyle}>この画面を閉じると、二度とこのパスワードが見れなくなります。</p>
              <p style={wellStyles.messageStyle}> 印刷するなどして控えてください。</p>
            </div>
            <Box mt={4} display="flex" alignItems="center" justifyContent="center">
              <Button
                color="secondary"
                variant="contained"
                size="large"
                onClick={() => {
                  window.print();
                }}
              >
                このページを印刷
              </Button>
            </Box>
            <br />
            <br />
            <br />
            <Snackbar
              message={`${this.props.institutionCreateForm.institution_id}が作成されました`}
              open={this.state.open}
              autoHideDuration={4000}
              action={
                <>
                  <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </>
              }
            />
          </form>
        );
      } else {
        // ボタン押下時にもバリデーションさせる仕組み.
        const { handleSubmit } = this.props;

        // デフォルト.
        return (
          <form style={wellStyles.formStyle}>
            {institutionNewField}
            <Box mt={4} display="flex" alignItems="center" justifyContent="center">
              <Button
                color="primary"
                variant="contained"
                size="large"
                disabled={this.props.institution.Confirming || this.props.invalid}
                onClick={handleSubmit(() => this.handlePreview(true))}
              >
                登録確認
              </Button>
            </Box>
            <br />
            <br />
            <br />
            {// API実行されている.
            (this.props.institution.SignedUp === true || this.props.institution.Confirmed === true) &&
            this.props.institution.err ? (
              <Error title={'施設情報登録時にエラーが発生しました'} message={this.props.institution.err.message} />
            ) : null}
          </form>
        );
      }
    }
  }
}

export default reduxForm<{}, any>({
  form: 'institutionCreateForm',
})(InstitutionCreateForm);
