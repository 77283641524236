import React, { Component } from 'react';
import UsersList from './UsersList';
import Loading from '../common/Loading';
import BackButton from '../common/BackButton';
import { Container, Box } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';

export interface StateProps {
  user: any;
  institution: any;
  match: any;
}

export interface DispatchProps {
  userActions: any;
  institutionActions: any;
}

export interface OwnProps extends RouteComponentProps<{}> {}

type Props = StateProps & DispatchProps & OwnProps;

export default class UsersNew extends Component<Props> {
  institutionSub: any;

  constructor(props: any) {
    super(props);
    this.institutionSub = this.props.match.params.institution_sub;
  }

  componentDidMount() {
    this.props.userActions.getUsersWithUniqueId(this.institutionSub);
    if (!this.props.institution) {
      this.props.institutionActions.get(this.institutionSub);
    }
  }

  render() {
    if (this.props.user.executing || !this.props.institution) {
      return <Loading />;
    } else if (this.props.user.error) {
      return <p>{this.props.user.error.message}</p>;
    } else {
      return (
        <>
          <BackButton />
          <Container maxWidth="md">
            <Box>
              <h2>{this.props.institution.institution_name}</h2>
            </Box>
            {this.props.user.listData.length > 0 ? (
              <UsersList
                users={this.props.user.listData}
                invalidUsers={[]}
                institutionSub={this.institutionSub}
                withLink={true}
              />
            ) : (
              <p>利用者が登録されていません。</p>
            )}
          </Container>
        </>
      );
    }
  }
}
