import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as ErrorActions from '../actions/Error';
import Error from '../components/common/Error';
import { RootState } from '../store/configureStore';
import { Dispatch } from 'redux';

function mapStateToProps(state: RootState) {
  return {
    error: state.error,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    errorActions: bindActionCreators(ErrorActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Error);
