import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { parse } from 'qs';
import * as Institution from '../constants/Institution';
import { Button, Table, TableBody, TableRow, TableCell, Box, Container, Grid } from '@material-ui/core';
import Loading from './common/Loading';
import BackButton from './common/BackButton';

export interface StateProps {
  institution: any;
  auth: any;
}

export interface DispatchProps {
  institutionActions: any;
}

export interface OwnProps extends RouteComponentProps<{}> {}

type Props = StateProps & DispatchProps & OwnProps;

export default class InstitutionDetail extends Component<Props> {
  componentDidMount = () => {
    const query = parse(this.props.location.search.substr(1));
    this.props.institutionActions.get(query.id);
  };

  render() {
    if (!this.props.institution.institution) {
      return <Loading />;
    }

    const institutionInfo = { ...this.props.institution.institution };
    institutionInfo.enabled = this.props.institution.institution.enabled ? '有効' : '無効';
    if (!('plan_type' in this.props.institution.institution)) {
      institutionInfo.plan_type = '未設定';
    }

    const infoKey = Object.assign(Institution.Model, {
      created_at: 'データ追加時間',
      updated_at: 'データ更新時間',
    });

    const institution = Object.keys(infoKey).map(key => {
      // eslint-disable-next-line no-prototype-builtins
      if (institutionInfo.hasOwnProperty(key)) {
        return (
          <TableRow key={key}>
            <TableCell>{infoKey[key]}</TableCell>
            <TableCell>{institutionInfo[key]}</TableCell>
          </TableRow>
        );
      } else {
        return null;
      }
    });

    const updateUrl = '/institutions/update?id=' + institutionInfo.institution_sub;
    const usersNewUrl = '/institutions/users/' + institutionInfo.institution_sub + '/new';
    const usersUrl = '/institutions/users/' + institutionInfo.institution_sub;

    return (
      <>
        <BackButton />
        <Container maxWidth="md">
          <Box m={3}>
            <Box mb={3} mt={3}>
              <Grid container direction="row" justify="flex-start" alignItems="center">
                <Box>
                  <Link to={updateUrl} style={{ textDecoration: 'none' }}>
                    <Button color="primary" variant="contained" size="large" disabled={!this.props.auth.isSuperUser}>
                      施設情報の更新
                    </Button>
                  </Link>
                </Box>

                <Box ml={2}>
                  <Link to={usersNewUrl} style={{ textDecoration: 'none' }}>
                    <Button color="primary" variant="contained" size="large">
                      利用者一括登録
                    </Button>
                  </Link>
                </Box>

                <Box ml={2}>
                  <Link to={usersUrl} style={{ textDecoration: 'none' }}>
                    <Button color="primary" variant="contained" size="large">
                      利用者一覧
                    </Button>
                  </Link>
                </Box>
              </Grid>
            </Box>

            <Table>
              <TableBody>{institution}</TableBody>
            </Table>
          </Box>
        </Container>
      </>
    );
  }
}
